<template>
    <div class="a-header">
        <!--左边 自适应窄边导航栏 -->
        <div class="admin-leftlanmu" >
            <el-dropdown trigger="click">
                <div class="admin-phonemenu">
                    <i style="font-size: 19px; margin-left: -10px;padding: 5px;" class="el-icon-menu"></i>
                </div>
                <el-dropdown-menu slot="dropdown">
                    <!-- <el-dropdown-item v-for="(ar,idx) in aRoutes" :key="idx">
                        <span @click="toroute(ar.a_route)">
                            {{ar.a_name}}
                        </span>                      
                    </el-dropdown-item>   -->
                    <el-tree :data="data" :props="defaultProps"  @node-click="handleNodeClick">
                        <span slot-scope="{ node, data }" style="font-size: 14px;">
                            <i :class="data.icon"></i>
                            <span style="padding-left: 4px;">{{node.label}}</span>
                        </span>

                    </el-tree>
                </el-dropdown-menu>
                
            </el-dropdown>          
        </div>

        <div class="admin-left-title">
               <img style="height: 35px;width:35px" src="../assets/images/logoko.png" alt="">
               <span>博客后台管理</span>
        </div>

        <div class="admin-right-header">
            <div>
                <div class="headerImage">
                    <!-- <img v-if="image==null"  src="../assets/images/header.png"> -->
                    <img v-if="!nAndImage.image" src="../assets/images/header.png">
                    <img :src="qqheader(nAndImage.image)">

                    <el-popconfirm                             
                                title="您确定退出吗？"
                                @confirm="logOff">
                        <div slot="reference" >
                            <span>退出</span>
                        </div>
                    </el-popconfirm>                  
                </div>
            </div>
            <div style="font-size: 14px;font-weight: 700;margin-left: 5px;">
                {{nAndImage.nickname}}
                <div style="font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;font-size:12px;padding-top:2px;font-weight:400;color:#c1bfbf">
                    <span v-if="nAndImage.image==2415648320">管理员</span>
                    <span v-else>普通用户</span>
                </div>
            </div>

          
            
                <!-- <span class="admin-nickname">     
                   昵称
                </span> -->


            <!-- <el-badge :value="2" class="item" type="warning">
                <el-button size="small">回复</el-button>
            </el-badge> -->
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
    computed:{
      ...mapGetters([
          'nAndImage'
      ]),
    }, 
    name:'AdminHeader',
    data(){
        return{
            data: [
                {
                    label: '首页',
                    a_route:'/index',
                    icon:'el-icon-s-home'
                },
                {
                    label: '文章管理',
                    icon:'el-icon-s-management',
                    children: [{
                        label: '文章发表',
                        a_route:"/article/write",
                        icon:'el-icon-document-add'
                    },{
                        label: '文章列表',
                        a_route:"/article/articleList",
                        icon:'el-icon-document-copy'
                    },{
                        label: '文章分类',
                        a_route:"/article/category",
                        icon:'el-icon-price-tag'
                    },{
                        label: '文章标签',
                        a_route:"/article/tag",
                        icon:'el-icon-pie-chart'
                    }]
                }, {
                    label: '相册随笔友链',
                    icon:'el-icon-star-on',
                    children: [{
                        label: '相册管理',
                        a_route:'/xcsb/xiangce',
                        icon:'el-icon-picture-outline-round'
                    }, {
                        label: '随笔管理',
                        a_route:'/xcsb/suibi',
                        icon:'el-icon-edit'
                    },{
                        label: '友链管理',
                        a_route:'/fl/friendLink',
                        icon:'el-icon-link'
                    },]
                },{
                    label: '工具管理',
                    icon:'el-icon-s-cooperation',
                    children: [{
                        label: '工具分类',
                        a_route:'/tl/type',
                        icon:'el-icon-receiving'
                    }, {
                        label: '工具',
                        a_route:'/tl/utool',
                        icon:'el-icon-suitcase'
                    }]
                },{
                    label: '用户角色权限',
                    icon:'el-icon-s-custom',
                    children: [{
                        label: '用户',
                        a_route:'/urp/user',
                        icon:'el-icon-user'
                    }, {
                        label: '角色',
                        a_route:'/urp/role',
                        icon:'el-icon-mobile'
                    }, {
                        label: '权限',
                        a_route:'/urp/permissions',
                        icon:'el-icon-key'
                    }]
                }, {
                    label: '网站设置',
                    icon:'el-icon-s-tools',
                    children: [{
                        label: '信息设置',
                        a_route:'/web/userSetting',
                        icon:'el-icon-user'
                    }, {
                        label: '基本设置',
                        a_route:'/web/jbSetting',
                        icon:'el-icon-set-up'
                    }]
                }, 
            ],
            defaultProps: {
                children: 'children',
                label: 'label',
            }
        }
    },
    methods:{
        // toroute(obj){
        //     this.$router.push({
        //         path:obj
        //     })
        //     console.log("idx:",obj)
        // },
        qqheader(qq){
            return 'https://q1.qlogo.cn/headimg_dl?dst_uin='+qq+'&spec=100'
        }, 

        
        handleNodeClick(data) {
            // console.log(data.a_route);
            this.$router.push({
                path:data.a_route
            })
        },
        logOff(){
            this.$message({
                    message:"已退出！",
                    type: 'success',
                    duration:1000,
                });
            this.$router.push({
                path:'/login',
            })
            sessionStorage.removeItem('nAndImage')
            sessionStorage.removeItem('token')
        },

        // getImageAndNickname(){
        //     console.log("这是id",this.$route.query.id)
        //     if(!this.$route.query.id){
        //         console.log("kong")
        //         return ""
        //     }
        //     getUserById(this.$route.query.id).then((res)=>{
        //         console.log("不kong",res)
        //         this.$store.commit('setUserData',res.data)
        //         // this.nAndImage=res.data
        //     })
        // }
    },
    mounted(){
        console.log("store:",nAndImage)
    },
    created(){
        // this.getImageAndNickname()
    }
}
</script>

<style>
.el-popover{
    min-width: 10px;
    padding: 5px;
    font-size: 12px;
    /* background-color: rgb(49, 49, 49);
    color: #ffffff; */
}
.el-popper[x-placement^=bottom] {
    margin-top: 10px !important;
}
.a-header{
    position: relative;
    width: 100%;
    height: 60px;
    /* height: 80px */
    /* background-color: rgba(224, 224, 224, 0.301); */
    background-color: rgb(49, 49, 49);
    z-index: 6;
    box-shadow: 5px 0px  5px 2px rgba(0, 0, 0, 0.3);
    /* border-bottom: 1px solid #9694943d; */
    backdrop-filter: blur(10px);
    color: #ffffff;
    /* box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.589) */
}
.admin-phonemenu i:hover{
    color: rgb(255, 255, 255);
    cursor: pointer;
    background: #0869fc;
    border-radius: 5px;
}
.admin-leftlanmu{
    z-index: 11;
    display: none;
    left: 10px;
    position: absolute;
    padding: 15px;
    justify-content: center;
    align-items: center;
    /* display: flex; */
}
.el-dropdown-menu {
    width: 140px;
    padding: 5px 10px;
}
.admin-leftlanmu span .el-icon-more{ 
    font-size: 20px;
    cursor: pointer;
    /* padding: 10px; */
}
.el-tree-node {
    margin: 5px 0;
}

.admin-left-title{
    position: absolute;
    /* width: 200px;  */
    z-index: 10;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 18px;
    /* color: aliceblue; */
}
.admin-right-header{
    /* z-index: 11; */
    right: 10px;
    height: 100%;
    /* padding: 5px; */
    position: absolute;
    z-index: 10;
    justify-content: center;
    align-items: center;
    display: flex;
    /* background-color: bisque; */
}
.admin-right-header .headerImage{
    height: 40px;
    width:40px;
    border-radius: 5px;
    border: 2px solid #ffffffc0;
    position: relative;
    overflow: hidden;
}
.admin-right-header img{
    /* background-color: aqua; */
    height: 100%;
    width:100%;
    position: absolute;
	object-fit: cover;
}
.admin-right-header .headerImage div{
    height: 100%;

    display: none;
    width:100%;
}
.admin-right-header .headerImage div span{
    transition: .3s;
    height: 100%;
    width:100%;
    color: white;
    font-size: 12px;
    background-color: #3b3b3b42;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
}

.admin-right-header>div:hover .headerImage div{
    transition: .3s;
    display: block;
    cursor: pointer;
}
.admin-right-header .admin-nickname{
    font-size: 15px;
    font-weight: 600;
    margin-left: 10px;
    cursor: pointer;
}
.el-popper .popper__arrow{
  display: none;
}
.el-popper[x-placement^=bottom] .popper__arrow {
    display: none;
}
.el-popper[x-placement^=bottom] {
    margin-top: 0px;
}
</style>