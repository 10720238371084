// 生成 html 的函数
// function attachmentToHtml(elem: SlateElement, childrenHtml: string): string {
function attachmentToHtml(elem, childrenHtml) { 
  console.log(elem,childrenHtml)
  const color=elem.isColor
  if(color=="blue"){

    return `<blockquote data-w-e-type="zdy" style="padding: 10px;border-radius: 3px;border-left: 6px solid #50BFFF;background: #ECF8FF; color: #409eff;" >${childrenHtml}</blockquote>`

  }else if(color=="red"){

    return `<blockquote data-w-e-type="zdy" style="padding: 10px;border-radius: 3px;border-left: 6px solid #F54C4C;background: #FEF0F0; color: #dc0000;" >${childrenHtml}</blockquote>`
  
  }else if(color=="green"){

    return `<blockquote data-w-e-type="zdy" style="padding: 10px;border-radius: 3px;border-left: 6px solid #67C23A;background: #F0F9EB; color: #0fc20c;" >${childrenHtml}</blockquote>`
  
  }else if(color=="black"){

    return `<blockquote data-w-e-type="zdy" style="padding: 10px;border-radius: 3px;border-left: 6px solid #8B8E94;background: #ECF8FF; color: #5d5d5d;" >${childrenHtml}</blockquote>`
  
  }else if(color=="orange"){

  return `<blockquote data-w-e-type="zdy" style="padding: 10px;border-radius: 3px;border-left: 6px solid #ffaa00;background: #FDF6EC; color: #ff850a;" >${childrenHtml}</blockquote>`
  
  }else if(color=="purple"){

    return `<blockquote data-w-e-type="zdy" style="padding: 10px;border-radius: 3px;border-left: 6px solid #BB2BEE;background: #ECD7F3;color: #c820ff;" >${childrenHtml}</blockquote>`
  
  }else{
    
    return `<blockquote>${childrenHtml}</blockquote>`
  }
  // const { style=''} = AttachmentElement 
}

// 配置
const conf = {
  type: 'blockquote', // 节点 type ，重要！！！
  elemToHtml: attachmentToHtml,
}

export default conf