<template>
    <!-- 定义标签 -->
    <div   
        v-resize="resize"
        :id="id"
        :style="{
            width: fwidth,
            height: newHeight,
        }"
    ></div>
</template>

<script>
import * as echarts from "echarts";
import { myMixin } from "@/utils/mixin";
// 2.生成一个 id 值给标签，目的是为了当多次使用组件防止id重复
const uid = function () {
    // return new Date().getTime();
    return Math.floor(Math.random() * (10000 - 0 + 1)) + 0;
};

export default {
    name:'MyECharts',
    mixins: [myMixin],

    //1.获取传过来的值
    props: {
        fwidth: {
            type: String,
        },
        fheight: {
            type: String,
        },
        foption: {
            type: Object,
        },
    },
    data() {
        return {
            //3.在这定义 id 与 myChart图表实例 ， 方便管理
            id: null,
            myChart: null,
            newHeight:0,
            heightNum:parseInt(this.fheight.substring(0,3)),
            mcWidth:document.body.clientWidth,
        };
    },

    methods:{
        createCharts(){
            // this.$nextTick(() => {
            // console.log("宽据：",this.fwidth)
            // console.log("高：",this.fheight)
            // // 指定图表的配置项和数据
            // console.log(this.mcWidth)
            // console.log("数据：",this.foption)
            this.myChart = echarts.init(document.getElementById(this.id));          
            this.myChart.setOption(this.foption);

            // })
        },
        hegihtAndWidthGenSui(){
            // console.log("宽据：")
            this.mcWidth=document.body.clientWidth
            
            if(this.mcWidth<800){
                this.newHeight=(this.heightNum-100)+"px"
            }else{
                this.newHeight=this.heightNum+"px"
            }
            // console.log(this.newHeight)
            // if(that.myChart){
            this.myChart.resize();   
        }
    },

    mounted() {
        this.newHeight=this.fheight   
        // console.log(this.heightNum)

        //   5. 创建echarts图表实例      
        this.$nextTick(() => {       
            this.createCharts()
        })
 

        // window.onresize = () => {

        //    console.log("了")
        //     this.mcWidth=document.body.clientWidth
        // // 使用刚指定的配置项和数据显示图表。
        //     this.myChart.setOption(this.foption);
        // }
        if(this.mcWidth<800){
            this.newHeight=(this.heightNum-100)+"px"
        }
     
        // var that = this;
        // window.onresize = () => {
        //     this.mcWidth=document.body.clientWidth
        //     if(this.mcWidth<800){
        //         this.newHeight=(this.heightNum-100)+"px"
        //     }else{
        //         this.newHeight=this.heightNum+"px"
        //     }
        //     // console.log(this.newHeight)
        //     // if(that.myChart){
        //     this.myChart.resize();
        //     // }
        // };
        window.addEventListener("resize", () => {   
            this.hegihtAndWidthGenSui()
        });
    },

    created() {
        // 4.id赋值
        this.id = uid();
    },
    beforeDestroy() { // 在组件生命周期结束的时候销毁。
		window.removeEventListener('resize',() => {   
            this.hegihtAndWidthGenSui()
        }, false)
	},

    // destroyed() {
	// 	// 组件销毁后解绑事件
	// 	window.onresize = null;
	// }
}
    
</script>