<template>
    <div>
        
        <div class="fbiao-top">
            <el-input v-model="articleForm.articleTitle"  size="small"  placeholder="文章标题"></el-input>
            <el-button  
            type="primary" 
            size="small"  
            style="margin-left: 5px;" 
            @click="fabiaowenz">
                {{$route.query.article_id>0?'编辑文章':'发表文章' }}
            </el-button>
            
            <!-- 对话框 -->
            <el-dialog
                title="发表文章"
                :visible.sync="centerDialogVisible"
                center>
                <div >
                    <!-- 文章分类 -->
                    <div class="fbwz-zi">
                        <span v-show="!articleForm.lCategory" style="color:red">*</span>

                        <div class="fbwz-zi-l">
                            文章分类
                        </div>
                        
                        <div class="fbwz-zi-r">
                            <template>
                                <el-select v-model="articleForm.lCategory" placeholder="请选择" size="small">
                                    <el-option
                                    v-for="(item,i) in floptions" 
                                    :key="i"
                                    :label="item.categoryName"
                                    :value="item.id">
                                    </el-option>
                                </el-select>
                            </template>
                        </div>
                        
                    </div>

                    <!-- 文章标签 -->
                    <div class="fbwz-zi">
                        <span v-show="articleForm.lTags.length == 0" style="color:red">*</span>
                        <div class="fbwz-zi-l">文章标签</div>
                        
                        <div class="fbwz-zi-r">
                            <div style="display: flex;flex-wrap: wrap;">
                                <!-- 选择的标签列 -->
                                <el-tag
                                    v-for="(tag,idx) in articleForm.lTags"
                                    :key="idx"
                                    @close="handleClose(tag)"
                                    type="success"
                                    style="margin:4px"
                                    closable>
                                    {{tag.tagName}}
                                </el-tag>
                                <!-- <el-button 
                                    type="primary" 
                                    @click="innerVisible = true" 
                                    size="small" 
                                    style="margin-left:4px"
                                    plain>
                                    选择标签
                                </el-button> -->

                                <!-- 标签嵌套内层 对话框Dialog增加新标签 -->
                                <!-- <el-dialog
                                    width="400px"
                                    :visible.sync="innerVisible"
                                    append-to-body>
                                    <span style="padding:10px">
                                        <div style="display:flex;justify-content: center;align-items: center;width: 80%;">
                                            <span style="width:100px">标签名字：</span>                   
                                            <el-input
                                            placeholder="请输入内容"
                                            v-model="tagForm.tagName"
                                            size="small"
                                            clearable>
                                            </el-input>
                                        </div>
                                        
                                        <div style="display:flex;margin-top:30px;align-items: center;">
                                            <span style="width:83px;">标签颜色：</span>
                                            <el-color-picker size="small" v-model="tagForm.tagColor"></el-color-picker>
                                        </div>
                                    </span>
                                    <span slot="footer" class="dialog-footer">
                                        <el-button size="small" @click="innerVisible = false">取 消</el-button>
                                        <el-button size="small" type="primary" @click="xinzengTag">添 加</el-button>
                                      
                                    </span>
                                </el-dialog> -->

                                <el-popover
                                    placement="bottom"
                                    width="250"                                   
                                    trigger="click">
                                    <div style=" display: flex;align-items: center; flex-wrap: wrap;">
                                        <el-button                                 
                                        v-for="(bq,idx) in bqlist"
                                        :key="idx"                                  
                                        @click="addbq(bq)"
                                        style="margin:5px"                             
                                        type="primary"  
                                        size="mini" 
                                        :disabled="tagClass(bq.id)"
                                        plain>
                                            {{ bq.tagName }}
                                        </el-button>

                                        <!-- <el-button 
                                            @click="innerVisible = true"
                                            plain size="mini" 
                                            style="margin: 5px;" 
                                            type="warning" 
                                            icon="el-icon-plus" circle>
                                        </el-button> -->
                                    </div>
                                    <!-- <el-button >手动激活</el-button> -->
                                    <el-button 
                                    type="primary" slot="reference" size="small" style="margin-left:4px;margin-top:4px" plain>
                                        选择标签
                                    </el-button>
                                </el-popover>  
                            </div>  
                            <!-- 弹出框  -->
                                                 
                           
                            <!-- <template>
                                <el-select v-model="bqvalue" placeholder="请选择" size="small">
                                    <el-option
                                    v-for="item in bqoptions"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                    </el-option>
                                </el-select>
                            </template> -->
                        </div>                      
                    </div>

                    <div class="fbwz-zi">
                        <div class="fbwz-zi-l">缩略图</div>                        
                        <div class="fbwz-zi-r">
                            <el-upload
                                class="avatar-uploader"
                                action="string"
                                :show-file-list="false"
                                :http-request="handleAvatarSuccess"
                                :before-upload="beforeAvatarUpload">
                                <img v-if="articleForm.articleCover" :src="files.myblogImageUrl+articleForm.articleCover" style="height: 110px;">
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                            <!-- <el-upload
                                class="avatar-uploader"
                                action="string"
                                :show-file-list="false"
                                :http-request="uploadImage"
                                :before-upload="beforeAvatarUpload">
                                <img v-if="imageUrl" :src="imageUrl" style="height: 110px;">
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload> -->
                        </div>                       
                    </div>
                  
                    <div class="fbwz-zi">
                        <div class="fbwz-zi-l">
                            是否置顶
                        </div>
                        
                        <div class="fbwz-zi-r">
                            <el-switch
                                v-model="articleForm.isTop"
                                :active-value="1" :inactive-value="0">
                            </el-switch>  
                        </div>
                         
                    </div>
                    <div class="fbwz-zi">
                        <div class="fbwz-zi-l">
                             是否推荐
                        </div>
                       
                        <div class="fbwz-zi-r">
                            <el-switch
                                v-model="articleForm.isRecommend"
                                :active-value="1" :inactive-value="0">
                            </el-switch>   
                        </div>
                         
                    </div>
                    <div class="fbwz-zi">
                        <div class="fbwz-zi-l">文章类型</div>
                        
                        <div class="fbwz-zi-r">
                           <template>
                                <el-radio v-model="articleForm.articleType" :label=1>原创</el-radio>
                                <el-radio v-model="articleForm.articleType" :label=2>转载</el-radio>
                                <el-radio v-model="articleForm.articleType" :label=3>翻译</el-radio>
                            </template>  
                        </div>
                                             
                    </div>
                </div>
                <span style=" display: flex;justify-content: center;align-items: center;margin-top:10px">
                    <el-button type="primary" size="small">取消</el-button>
                    <el-button v-if="$route.query.article_id" type="primary" size="small" @click="successBianji()">完成编辑</el-button>
                    <el-button v-else type="primary" size="small" @click="fabu()">发布</el-button>
                </span>
               
            </el-dialog>
            
        </div>
        <div class="fw-zs" id="fw-zs">          
                <fu-wen-ben :htmldata1="articleForm.articleText"></fu-wen-ben>  
        </div>
    </div>
    
</template>

<script>
import FuWenBen from '@/components/wenzhang/FuWenBen'
import ZhangShi from '@/components/wenzhang/ZhangShi'
import { addArticle, getArticleVoById, updateArticle } from '@/api/article'
import { getTagAll, addTag } from '@/api/tags'
import { getCategoryAll } from '@/api/category'
import { shangchuanImages } from '../api/uplod'
// import { watch } from 'vue'
export default {
    components: { 
        FuWenBen,
        ZhangShi 
    },
    name:'FaBiaoAndBianji',
    data(){
        return{
            centerDialogVisible: false,

            // innerVisible: false,
            // tagForm:{
            //     tagName:'',
            //     tagColor:''
            // },


            articleForm:{
                userId:1, 
                articleTitle:'',
                articleText:'',
                // 分类
                lCategory: '',
                // 缩略图
                articleCover: '',
                // 标签列
                lTags:[],
                // 是否置顶
                isTop: 0,
                // 是否推荐
                isRecommend: 0,
                // 文章类型
                articleType: 1,
            },
            floptions: [],
            bqlist:[],
        }
    },

    methods: {
        getHtml(fuWenHtml){
            if(fuWenHtml){
                this.articleForm.articleText=fuWenHtml
                // console.log("我是发表和编辑页面,传过来的值：",fuWenHtml)
            }
            
        },

        // 发表文章按钮
        fabiaowenz(){
            if(!this.articleForm.articleTitle){
                return this.$message({
                    message: '文章标题不能为空',
                    type: 'warning'
                });
            }
            if(this.articleForm.articleText!='<p><br></p>'){
                // 打开对话框
                this.centerDialogVisible = true
                // 获取标签列               
                getTagAll('').then((res)=>{
                    this.bqlist=res.data
                    // console.log("tags",res.data)
                })
                // 获取分类列
                getCategoryAll('').then((res)=>{
                    this.floptions=res.data
                    // console.log("categorys",res.data)
                })
                // 调接 
            }else{
                this.$message({
                    message: '文章内容不能为空',
                    type: 'warning'
                });
            }        
        },

        // 选择标签
        addbq(bq){
            this.$nextTick(() => {
                // console.log("标签",bq)
                this.articleForm.lTags.push(bq)
            })
        },

        // 取消选择的标签
        handleClose(tag) {
            this.articleForm.lTags.splice(this.articleForm.lTags.indexOf(tag), 1);
        },

        // 选中后标签变为无法选择
        tagClass(bq_id){
            //  if(this.articleForm.lTags){
            //     console.log("hhhhhhh红红火火或：",bq)
            //     this.articleForm.lTags.forEach(item => {
            //         if(item.id==bq.id){
            //             console.log("我是对的")
            //             return true
            //         }
            //     })
            // }
            // console.log("tags",this.articleForm.lTags)  
            const tagIdList=[]
            for (let i = 0; i < this.articleForm.lTags.length; i++) {
                tagIdList[i]=this.articleForm.lTags[i].id
            }
            // console.log("标签",bq_id)  
            // console.log("数组",tagIdList)  

            const index = tagIdList.indexOf(bq_id);             
            // console.log("index",index)  
            return index !== -1 ? true :false;   
            // const index = this.articleForm.lTags.indexOf(bq_id);   
            // console.log("index",index)  
            // return index !== -1 ? true :false;   
            // return index !== -1 ? "tag-item-no" : "tag-item-yes";    
            
        },

        // 新增标签
        // xinzengTag(){
        //     addTag(this.tagForm).then(res=>{
        //         if(res.code==200){
        //             this.$message({
        //                 message:res.message,
        //                 type: 'success',
        //                 duration:1000,
        //             });  
        //             this.bqlist=''
        //             getTagAll('').then((res)=>{
        //                 this.bqlist=res.data
        //             })
        //             this.innerVisible=false
        //         }else{
        //                 this.$message({
        //                 message:res.message,
        //                 type: 'error',
        //                 duration:1000,
        //             });
        //         } 
        //     })
        // },

        // 上传缩略图
        handleAvatarSuccess(row) {
            const formData=new FormData()
            formData.append('file',row.file)
            shangchuanImages(formData).then((res)=>{
                this.$message({
                message:res.message,
                type: 'success',
                duration:1000,
                });
                // this.imageUrl=res.data
                this.articleForm.articleCover=res.data
            })
        },

        // 上传前判断
        beforeAvatarUpload(file) {
            const isjpg = file.type === 'image/jpg';
                const isjpeg = file.type === 'image/jpeg';
                const isgif = file.type === 'image/gif';
                const ispng = file.type === 'image/png';
                const isJPG = file.type === 'image/JPG';
                const isJPEG = file.type === 'image/JPEG';
                const isGIF = file.type === 'image/GIF';
                const isPNG = file.type === 'image/PNG';
                if (!(isJPG || isJPEG || isGIF || isPNG || isjpg || isjpeg || isgif || ispng)) {
                    this.$message.error('只能上传JPG 、JPEG 、GIF、 PNG格式的图片!');
                }

            const isLt2M = file.size / 1024 / 1024 < 10;
                if (!isLt2M) {
                    this.$message.error('上传单张图片大小不能超过10MB!');
                }
                return (isJPG || isJPEG || isGIF || isPNG || isjpg || isjpeg || isgif || ispng) && isLt2M;
        } ,

        // 发布文章
        fabu(){
            if(!this.articleForm.lCategory){
                return this.$message({
                    message: '分类不能为空',
                    type: 'warning'
                });
            }
            if(this.articleForm.lTags.length==0){
                return this.$message({
                    message: '请选择至少一个标签',
                    type: 'warning'
                });
            }
            if(!this.articleForm.articleCover){
                return this.$message({
                    message: '请上传缩略图',
                    type: 'warning'
                });
            }
            //准备参数
            // let param = {};
            // console.log("数据：",this.articleForm)              
            addArticle(this.articleForm).then((res)=>{
                if(res.code==200){
                    this.$message({
                    message:res.message,
                    type: 'success',
                    duration:1000,                       
                    });  
                    this.centerDialogVisible = false
                }else{
                        this.$message({
                        message:res.message,
                        type: 'error',
                        duration:1000,
                    });
                } 
            })           
        },

        // 编辑
        getArticleByIdtoBianji(){
            // console.log("床过来的id",this.$route.query.article_id)
            if(this.$route.query.article_id){
                getArticleVoById(this.$route.query.article_id).then(res=>{
                    this.articleForm=res.data
                    this.articleForm.lCategory=this.articleForm.lCategory.id
                    // console.log(this.articleForm)
                })
            }
        },

        // 完成编辑按钮
        successBianji(){
    
            updateArticle(this.articleForm).then(res=>{
                if(res.code==200){
                    this.$message({
                        message:res.message,
                        type: 'success',
                        duration:1000,
                        });  
                        this.getArticleByIdtoBianji()
                        this.centerDialogVisible = false
                    }else{
                        this.$message({
                        message:res.message,
                        type: 'error',
                        duration:1000,
                    });
                } 
            })
        }
    },

    mounted(){  
        this.$bus.$on("getHtmlData",this.getHtml)    

        // console.log("编辑的文章id",this.$route.query.article_id) 
        // this.getArticleByIdtoBianji()
    },
    created(){
        this.getArticleByIdtoBianji()
    },

    beforeDestroy() {
        this.$bus.$on("getHtmlData")
    },
    
}
</script>

<style>
.fbiao-top{
    padding: 10px 10px 0 10px;
    /* height: 150px; */
    /* width: 100%; */
    display: flex;
    /* background-color: rgb(99, 236, 236); */
}
/* 对话框 */
.el-dialog__wrapper .el-dialog {
    width:600px;
}
.fbiao-top .fbwz-zi{
    padding-left: 10px;
    display: flex;
    /* justify-content: center; */
    align-items: center;
}
.fbiao-top .fbwz-zi .fbwz-zi-l{
   /* width: 60px; */
}
.fbiao-top .fbwz-zi .fbwz-zi-r{
   margin-left: 10px;
   padding: 10px;
   display: flex;
}
.fbiao-top .fbwz-zi .fbwz-zi-r .avatar-uploader-icon {
    width: 110px;
    height: 110px;
    line-height: 110px
}
.fbiao-top .fbwz-zi .fbwz-zi-r .el-upload {
    width: auto;
}
/* .tag-item-yes{
    cursor: pointer;
}

.tag-item-no{
    cursor: not-allowed;
    color: #ccccd8 !important;
} */

/* 饿了么对话框自定义 */
/* .fbwz-zi-r .el-dialog__wrapper .el-dialog--center  {
    background-color: #6c6c6c8e;
}
.fbwz-zi-r .el-dialog__wrapper .el-dialog--center .el-dialog__body {
    padding: 0;
}
.fbwz-zi-r .el-dialog__wrapper  .el-dialog__header {
    display: none;
} */



.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: inherit;
}
.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 130px;
    height: 130px;
    line-height: 130px;
    text-align: center;
}
.avatar {
    width: 110px;
    height: 110px;
    /* display: block; */
}
.fw-zs{
    /* display: flex; */
    padding: 10px;
    height: calc(100vh - 150px);
    /* background-color: #409EFF; */
     /* text-align: center; */
    justify-content: center;
    align-items: center;
}
@media (max-width:950px) {		
    /* 搜索对话框宽度 */
    .el-dialog__wrapper .el-dialog {
        width:calc(70% - 20px);     
    }
}
@media (max-width:690px) {		
    /* 搜索对话框宽度 */
    .el-dialog__wrapper .el-dialog {
        width:calc(100% - 20px);
     
    }
}
</style>