import request from "@/utils/requests";

// 添加
export const addSuiBi=(param) =>request(
    {url:'/suibi/add',
    method:'post',
    data:param
})

// 获取所有随笔
export const getAllSuiBi=(search) =>request(
    {url:`/suibi/getAll?search=${search}`,
    method:'get',
    // data:param
})

//编辑
export const updateSuiBi=(param) =>request(
    {url:'/suibi/update',
    method:'post',
    data:param
})

// 编辑页面按id获取文章
export const deleteSuiBiById=(id) =>request(
    {url:`/suibi/delete?id=${id}`,
    method:'get',
    // data:param
})