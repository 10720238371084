import request from "@/utils/requests";

export const getCategoryAll=(search) =>request(
    {url:`/category/getAll?search=${search}`,
    method:'get',
    // data:param
})

// 增加分类
export const addCategory=(param) =>request(
    {url:'/category/add',
    method:'post',
    data:param
})

// 永久删除
export const foreverDeleteCategory=(id) =>request(
    {url:`/category/delete?id=${id}`,
    method:'get',
    // data:param
})

// 编辑分类
export const updateCategory=(param) =>request(
    {url:'/category/update',
    method:'post',
    data:param
})
