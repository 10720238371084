import request from "@/utils/requests";


// 添加相册
export const addXiangCe=(param) =>request(
    {url:'/xc/add',
    method:'post',
    data:param
})

// 获取所有相册
export const getAllXiangCe=(search) =>request(
    {url:`/xc/allList?search=${search}`,
    method:'get',
    // data:param
})


//编辑相册
export const updateXiangCe=(param) =>request(
    {url:'/xc/update',
    method:'post',
    data:param
})

//暂时删除相册
export const isDeleteXiangce=(param) =>request(
    {url:'/xc/upDelete',
    method:'post',
    data:param
})

// 回收
export const huishouXiangce=(param) =>request(
    {url:'/xc/hsxc',
    method:'post',
    data:param
})

// 永久删除
export const foreverDeleteXiangce=(id) =>request( 
    { url: `/xc/delete/${id}`,
    method:'get',
    // data:param
})