<template>
    <canvas class="background"></canvas>
</template>

<script>
import Particles from "@/utils/particles"
export default {
    data(){
        return{
        }
    },
    async mounted() {
        this.$nextTick(() => {
        // window.onload = function () { 
    
            Particles.init({
                // 选择canvas画布元素
                selector: '.background',
                // 设置最大粒子数
                maxParticles: 100,
                // 设置粒子的移动速度
                speed: 0.5,
                // 设置粒子颜色
                color: '#8ACAFF',
                // 开启点连线
                connectParticles: true,
                // 设置
                minDistance: 140,
                // 设置响应式配置项的调整
                responsive: [
                    {
                        // 当浏览器窗口宽小于768像素大小采用以下配置
                        breakpoint:768,
                        options: {
                            maxParticles: 100,
                        }
                    }, {
                        // 当浏览器窗口宽小于425像素大小采用以下配置
                        breakpoint:425,
                        options: {
                            maxParticles:50,
                        }
                    }, {
                        // 当浏览器窗口宽小于320像素大小采用以下配置
                        breakpoint:320,
                        options: {
                            maxParticles:20
                        }
                    }
                ]

            })
        // }
    })
    },
    // async mounted() {
    //     await this.$nextTick()
    // },
    methods: {
    },
    beforeDestroy() {
        // 销毁
        Particles.destroy();
    }
    
}
</script>

<style>
.background {
    background-color: #00000000;
    position:absolute;
    display:block;      
    top: 0;  
    left: 0; 
    z-index: 1;
}
</style>