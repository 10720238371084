<template>
    <div>
        <div class="ax-title">
            <span>文章分类</span>
        </div>
        <div class="article-list-top">
            <div style="display: flex;position: relative;">
                <el-button
                size="small" 
                @click="xinzengbutton"
                type="primary" plain>
                <i class="el-icon-plus"></i>
                    新增
                </el-button>

                <el-button 
                size="small" 
                style="margin-right:10px"
                type="danger" 
                plain>
                    批量删除
                </el-button>

                <el-input
                    placeholder="请输入搜索内容回车即可"
                    v-model="search"
                    size="small"
                    prefix-icon="el-icon-search"
                    @keyup.enter.native="searchCategoryList()"
                    style=""
                    clearable>
                </el-input>
               
                    <el-button 
                    style="margin-left:10px;position:absolute;margin-top:3px;border:none;right:3px"
                    size="mini" 
                    type="primary">
                        搜索
                    </el-button>
                <!-- </div> -->               
            </div>
        </div>

        <div class="article-list-body">
            <div class="articlelist-table">
                <template>
                    <el-table
                        :data="tableData.slice((pageNum - 1) * pageSize, pageNum * pageSize)"
                        :border="true"
                        :default-sort = "{prop: 'date', order: 'descending'}"
                        >
                        <el-table-column
                        header-align="center"
                        prop="createTime"
                        label="创建时间"
                        sortable
                        width="150">
                        </el-table-column>

                        <el-table-column
                        header-align="center"
                        prop="categoryName"
                        label="分类"
                        width="150">
                        </el-table-column>

                        <el-table-column
                        header-align="center"
                        prop="categoryImage"
                        label="缩略图"
                        width="">
                            <template slot-scope="scope">      
                                <img style="height: 100px;" :src="files.myblogImageUrl+scope.row.categoryImage" alt="">
                            </template>
                        </el-table-column>

                        <el-table-column
                        header-align="center"
                        prop="lArticles"
                        label="文章数量"
                        width="150">
                            <template slot-scope="scope">     
                                {{ scope.row.lArticles.length }}                               
                            </template>
                        </el-table-column>
                        <el-table-column
                        header-align="center"                 
                        prop="address"
                        label="管理"
                        width="100">
                          <template slot-scope="scope">
                            <div style="display:flex">
                                <el-button type="primary" size="mini" icon="el-icon-edit" circle @click="bianji(scope.row)"></el-button>
                                <el-popconfirm                             
                                    title="您确定删除这该分类吗？"
                                    @confirm="isDelete(scope.row.id)">
                                    <el-button title="删除分类" slot="reference"  type="danger" size="mini" icon="el-icon-delete" circle style="margin-left: 20px;"></el-button>
                                </el-popconfirm>
                            </div>                          
                          </template>
                        </el-table-column>
                    </el-table>
                </template>
            </div>
        </div>

        <div style="padding: 10px;">
          <el-pagination  
            :current-page="pageNum"
            :page-sizes="[5, 10, 20, 30]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="tableData.length"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange">
          </el-pagination>
        </div>

        <!-- 对话框 -->
        <el-dialog
            :title="categoryForm.id?'编辑分类':'添加分类'"
            :visible.sync="centerDialogVisible">
                <span>
                    <div style="display:flex;justify-content: center;align-items: center;">
                        <div style="width:100px"><span>分类名字：</span>   </div>                 
                        <el-input
                        placeholder="请输入分类名"
                        v-model="categoryForm.categoryName"
                        size="small"
                        clearable>
                        </el-input>
                    </div>

                     <div style="display:flex;margin-top:10px;align-items: center;">
                        <div style="width:100px;"><span>缩略图：</span></div>
                        <div class="fbwz-zi-r">
                            <el-upload
                                class="avatar-uploader"
                                action="string"
                                :show-file-list="false"
                                :http-request="handleAvatarSuccess"
                                :before-upload="beforeAvatarUpload">
                                <img v-if="categoryForm.categoryImage" :src="files.myblogImageUrl+categoryForm.categoryImage" style="height: 110px;">           
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                        </div>  
                    </div>               
                </span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="centerDialogVisible = false">取 消</el-button>
                <el-button v-if="!categoryForm.id" type="primary" @click="tianjiaCategory()">添 加</el-button>
                <el-button v-else type="primary" @click="successBianji()">确认修改</el-button>
            </span>
        </el-dialog>


    </div>
</template>

<script>
import { shangchuanImages } from '@/api/uplod';
import { addCategory, foreverDeleteCategory, getCategoryAll, updateCategory } from '../api/category';
export default {
    name:"ArticleCategory",
    data() {
        return {
            search:'',
            tableData: [],
            centerDialogVisible: false,
            categoryForm:{
                id:'',
                categoryName:'',
                categoryImage:''
            },

            pageNum:1,
            pageSize:10,
        }
    },
    methods:{
        xinzengbutton(){
            this.centerDialogVisible = true
            this.categoryForm={
                id:'',
                categoryName:'',
                categoryImage:''
            }
        },
        // 上传缩略图
        handleAvatarSuccess(row) {
            const formData=new FormData()
            formData.append('file',row.file)
            shangchuanImages(formData).then((res)=>{
                this.$message({
                message:res.message,
                type: 'success',
                duration:1000,
                });
                // this.imageUrl=res.data
                this.categoryForm.categoryImage=res.data
            })
        },
        beforeAvatarUpload(file) {
            const isjpg = file.type === 'image/jpg';
                const isjpeg = file.type === 'image/jpeg';
                const isgif = file.type === 'image/gif';
                const ispng = file.type === 'image/png';
                const isJPG = file.type === 'image/JPG';
                const isJPEG = file.type === 'image/JPEG';
                const isGIF = file.type === 'image/GIF';
                const isPNG = file.type === 'image/PNG';
                if (!(isJPG || isJPEG || isGIF || isPNG || isjpg || isjpeg || isgif || ispng)) {
                    this.$message.error('只能上传JPG 、JPEG 、GIF、 PNG格式的图片!');
                }

            const isLt2M = file.size / 1024 / 1024 < 5;
                if (!isLt2M) {
                    this.$message.error('上传单张图片大小不能超过5MB!');
                }
                return (isJPG || isJPEG || isGIF || isPNG || isjpg || isjpeg || isgif || ispng) && isLt2M;
        } ,
        // 获取所有分类
        searchCategoryList(){
            getCategoryAll(this.search).then((res)=>{
                 this.tableData=res.data
            })
        },
        // 添加分类
        tianjiaCategory(){
            // console.log("分类：",this.categoryForm)
            addCategory(this.categoryForm).then(res=>{
                if(res.code==200){
                    this.$message({
                        message:res.message,
                        type: 'success',
                        duration:1000,
                    });  
                    this.searchCategoryList()
                    this.centerDialogVisible=false
                }else{
                        this.$message({
                        message:res.message,
                        type: 'error',
                        duration:1000,
                    });
                } 
            })
        },

        bianji(row){
            this.centerDialogVisible=true;
            this.categoryForm=row
        },
        // 完成编辑
        successBianji(){
            updateCategory(this.categoryForm).then(res=>{
                if(res.code==200){
                    this.$message({
                        message:res.message,
                        type: 'success',
                        duration:1000,
                    });  
                    this.searchCategoryList()
                    this.centerDialogVisible=false
                }else{
                        this.$message({
                        message:res.message,
                        type: 'error',
                        duration:1000,
                    });
                } 
            })
        },

        isDelete(id){
            // console.log(id)
            foreverDeleteCategory(id).then(res=>{
                if(res.code==200){
                    this.$message({
                        message:res.message,
                        type: 'success',
                        duration:1000,
                    });  
                    this.searchCategoryList()
                }else{
                        this.$message({
                        message:res.message,
                        type: 'error',
                        duration:1000,
                    });
                } 
            })
        },
        handleSizeChange(val) {//改变当前页的个数
            this.pageSize=val
            // console.log(`每页 ${val} 条`);
            // this.getSh()
        },
        handleCurrentChange(val) {//改变当前页码
              this.pageNum=val
              // console.log(`当前页: ${val}`);
              // this.getSh()
        }
    },
    created(){
        this.searchCategoryList()
    }
}
</script>

<style>
.avatar-uploader-icon {
    width: 110px;
    height: 110px;
    line-height: 110px
}
.el-upload {
    width: auto;
}
</style>