<template>
   <div class="article">
        <!-- <button @click="jieshou">接收</button> -->
        <div class="html-text"  v-html="htmldata2" @click="imageChagange($event)"></div>

        <!-- v-html解析图片点击浏览 -->
        <transition
            enter-active-class="animate__animated animate__fadeIn 0.5s"
            >
            <image-viewer
                v-if="showViewer"
                :url-list="srcListView"
                :on-close="closeViewer"
                :z-index="3000"
            />
        </transition>
    </div>
</template>

<script>
// import Hljs from 'highlight.js'
import hljs from 'highlight.js'
import ImageViewer from 'element-ui/packages/image/src/image-viewer'
export default {
    name:'ZhangShi',
    components: {
        ImageViewer
    },
    props:{
        htmldata2:{
            type: String,
        }
    },
    data(){
        return{
            // htmlData:'<h1>飒飒啊</h1><p><br></p><h2>dsdds</h2><p><br></p><h3>我是个h3s</h3><br><pre><code>dhsdfskfjkshfkhskfhshkhfkhfdksfhkshakjhdjkaghdjkahkjdgakjgdjkaabcdefg<br>jdksajdkajdk<br>jsdhdjshj</code></pre><p><br></p><h1>sasasasa</h1><p><br></p><h2>dsdds</h2>',
            titleList:[],

            srcListView:'',
            // srcList: [], // 当前要放大的图片存放位置
            showViewer: false,
        }
    },
    methods:{

            //富文本渲染文本点击
        imageChagange(e){ 
            if (e.target.nodeName === 'IMG'|| e.target.nodeName == 'img') { //判断是否图片标签
                const img = e.target.currentSrc //获取点击图片地址
                this.onPreview([img]) 
                // this.srcList.push(img)
            }
        },
        //开启富文本图片预览
        onPreview(img) {
                this.showViewer = true;
                this.srcListView = img
                const m = (e) => { e.preventDefault() };
            document.body.style.overflow = 'hidden';
            document.addEventListener("touchmove", m, false); // 禁止页面滑动
        },
        //关闭富文本渲染文本图片预览
        closeViewer() {
                this.showViewer = false;
                const m = (e) => { e.preventDefault() };
                console.log("我关掉了")
                document.body.style.overflow = 'auto';
                document.removeEventListener("touchmove", m, true);
        },



        // getHtml(fuWenHtml){
        //     this.htmlData=fuWenHtml
        //     console.log("传过来的值：",fuWenHtml)
        // },

        getTitles(){
            const anchors =document.querySelectorAll('h1,h2,h3')
            anchors.forEach((i)=>{
                // i.
            })
            const titles = Array.from(anchors).filter((t) => !!t.innerText.trim())
            // console.log("anchors:",anchors)
            // console.log("titles:",titles)
            if (!titles.length)
                this.titleList.value = []
            const hTags = Array.from(new Set(titles.map((t) => t.tagName))).sort()
            this.titleList.value = titles.map((el, idx) => {
            return {
                title: el.innerText,
                // lineIndex: el.getAttribute('data-v-md-line'),
                indent: hTags.indexOf(el.tagName),
                }
            })
        },

        upMOD(){
            this.$nextTick(() => {
                
            // setTimeout(() => {
                const ifms=document.querySelectorAll(".article iframe")
                ifms.forEach((item) => {
                    let pre = item.parentElement;
                    // console.log("呀呀呀111",pre)
                    pre.setAttribute('style', 'position: relative;padding: 30% 45%;')
                })

                const code = document.querySelectorAll('.article pre code');
                // console.log("呀呀呀222",code)
                // const code=document.getElementsByTagName("pre")
                // console.log('这是code',code)
                code.forEach((item) => {
                    // 取出 code 的父元素 pre（后面方便使用）
                    let pre = item.parentElement;
                    // this.dragAround(item);
                    // 新建元素 代码块序号
                    let lineNumBox = document.createElement('div');
                    lineNumBox.setAttribute('style', 'height: ' + item.offsetHeight + 'px');
                    lineNumBox.className = 'line-num-box';
                    // 插入序号 计算方式：获取code元素的高并除以行高，得到行数，根据行数插入序号
                    let num = '';  // 设行高二十
                    for (let i = 1; i <= Math.ceil(item.offsetHeight / 25); i++) {
                        num += i + '\n'; // 序号加上换行符
                    }
                    lineNumBox.innerText = num;// 插入序号
                    item.parentElement.insertBefore(lineNumBox, item);

                    let codeBox = document.createElement('div');
                    codeBox.className = 'code-box';
                    codeBox.appendChild(item);

                    pre.appendChild(codeBox);

                    let lang = pre.lastElementChild.firstElementChild.className;
                    // console.log("lang:",lang)
                    let icon = `<div class="mac-icon">` +
                        `<span class="mac-icon-red"></span>` +
                        `<span class="mac-icon-yellow"></span>` +
                        `<span class="mac-icon-green"></span>` +
                        // `<span class="mac-icon-yuan"></span>` +
                        `<span class="mac-icon-lang">${lang.split('-')[1]}</span>` +
                        `<button class="copy-button">复制</button>` +
                        // `<button class="full-screen-button">全屏</button>` +
                        `</div>`;
                    pre.insertAdjacentHTML('afterbegin', icon);

                    // 获取复制元素
                    let copyButton = pre.firstElementChild.getElementsByClassName('copy-button')[0];
                    copyButton.onclick = function () {
                        // https://developer.mozilla.org/zh-CN/docs/Web/API/Clipboard/writeText
                        const copyPromise = navigator.clipboard.writeText(pre.lastElementChild.innerText);
                        copyPromise.then(() => {
                            alert('复制成功');
                        }).catch(() => {
                            alert('复制失败');
                        });
                    };
                    hljs.highlightBlock(codeBox.firstElementChild);
                
                });
                // },3000) 
            });

        }
    },

    async mounted() {
        // this.$bus.$on("getHtmlData",this.getHtml)

        // this.$nextTick(() => {
        //     // setTimeout(() => {
        //         const code = document.querySelectorAll('pre code');
        //         // const code=document.getElementsByTagName("pre")
        //         console.log('这是code',code)
            
        //         code.forEach((item) => {
        //             // 取出 code 的父元素 pre（后面方便使用）
        //             let pre = item.parentElement;
        //             console.log('这是pre',pre)
        //             console.log('这是item',item)
        
        //             // this.dragAround(item);
        //             // 新建元素 代码块序号
        //             let lineNumBox = document.createElement('div');
        //             lineNumBox.setAttribute('style', 'height: ' + item.offsetHeight + 'px');
        //             lineNumBox.className = 'line-num-box';
        //             // 插入序号 计算方式：获取code元素的高并除以行高，得到行数，根据行数插入序号
        //             let num = '';  // 设行高二十
        //             for (let i = 1; i <= Math.ceil(item.offsetHeight / 20); i++) {
        //                 num += i + '\n'; // 序号加上换行符
        //             }
        //             lineNumBox.innerText = num;// 插入序号
        //             item.parentElement.insertBefore(lineNumBox, item);

        //             let codeBox = document.createElement('div');
        //             codeBox.className = 'code-box';
        //             codeBox.appendChild(item);

        //             pre.appendChild(codeBox);

        //             let lang = pre.lastElementChild.firstElementChild.className;
        //             console.log("lang:",lang)
        //             let icon = `<div class="mac-icon">` +
        //                 // `<span class="mac-icon-red"></span>` +
        //                 // `<span class="mac-icon-yellow"></span>` +
        //                 // `<span class="mac-icon-green"></span>` +
        //                 `<span class="mac-icon-yuan"></span>` +
        //                 // `<span class="mac-icon-lang">${lang.split('-')[1].toUpperCase()}</span>` +
        //                 `<button class="copy-button">复制</button>` +
        //                 // `<button class="full-screen-button">全屏</button>` +
        //                 `</div>`;
        //             pre.insertAdjacentHTML('afterbegin', icon);

            
        //             // 获取复制元素
        //             let copyButton = pre.firstElementChild.getElementsByClassName('copy-button')[0];
        //             copyButton.onclick = function () {
        //                 // https://developer.mozilla.org/zh-CN/docs/Web/API/Clipboard/writeText
        //                 const copyPromise = navigator.clipboard.writeText(pre.lastElementChild.innerText);
        //                 copyPromise.then(() => {
        //                     alert('复制成功');
        //                 }).catch(() => {
        //                     alert('复制失败');
        //                 });
        //             };
                    
        //             Hljs.highlightBlock(codeBox.firstElementChild);
        //         });
            
        //         // },3000) 
        //     });
        

        // 获取h1,h2,h3标签的内容 
        this.getTitles()
    },

    watch: {
        htmldata2(newName, oldName) {
            // console.log("新的",newName)
            // console.log("老的",oldName)
            this.upMOD()
        }
    } ,

    // beforeDestroy() {
    //     this.$bus.$on("getHtmlData")
    // },
}
</script>

<style src='../../assets/css/zhangshi.css' scoped>

</style>