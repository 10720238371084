<template>
    <div>
        <div style="padding: 10px 10px 0 10px;">
            <el-button type="primary" size="mini" plain @click="xinzeng">新增</el-button>
        </div>

        <div class="articlelist-table">
            <template>
                <el-table
                    :data="tableData"
                    :border="true"
                    :default-sort = "{prop: 'date', order: 'descending'}"
                    >
                    <el-table-column
                    header-align="center"
                    prop="createTime"
                    label="创建时间"
                    sortable
                    width="120">
                    </el-table-column>

                    <el-table-column
                    header-align="center"
                    prop="articleList"
                    label="头像"
                    min-width="100"
                    >
                        <template slot-scope="scope">     
                            <img v-if="!scope.row.avatar" style="height: 60px;border-radius: 10px;" :src="'https://q1.qlogo.cn/headimg_dl?dst_uin='+scope.row.userQq+'&spec=100'" >                             
                            <img v-else  style="height: 60px;border-radius: 10px;" :src="scope.row.avatar" >                             
                        
                        </template>
                    </el-table-column>
                

                    <el-table-column
                    header-align="center"
                    prop="nickname"
                    label="昵称"
                    width="100">
                    </el-table-column>

                    <el-table-column
                    header-align="center"
                    prop="username"
                    label="账号"
                    width="100"
                    >
                    </el-table-column>

                    <el-table-column
                    header-align="center"
                    prop="userQq"
                    label="QQ"
                    width="100"
                    >
                    </el-table-column>
                    
                    <el-table-column
                    header-align="center"
                    prop="articleList"
                    label="角色"
                    min-width="200"
                    >
                        <template slot-scope="scope">     
                            <el-tag
                                v-for="(role,idx) in scope.row.roles"
                                :key="idx"
                                type="success"
                                @close="handleClose(scope.row.id,role.id)"
                                style="margin:4px"
                                closable>
                                {{role.roleName}}
                            </el-tag>                                                   
                            <!-- 弹出框  赋予/添加角色-->
                            <el-popover
                                placement="bottom"
                                width="200"
                                trigger="click">
                                <div>
                                    授予角色
                                </div>
                                <div style=" display: flex;justify-content: center;align-items: center;padding: 5px;  flex-wrap: wrap;">
                                    <el-button                                 
                                    v-for="(ro,idx) in roleList"
                                    :key="idx"                                  
                                    @click="addro(ro.id,scope.row.id)"
                                    style="margin:5px 10px"                             
                                    type="primary"  
                                    size="mini" 
                                    :disabled="roleClass(ro.id,scope.row.roles)"
                                    plain>
                                        {{ ro.roleName }}
                                    </el-button>
                                </div>
                                <!-- <el-button >手动激活</el-button> -->
                                <el-button type="primary"  slot="reference" circle size="small" plain icon="el-icon-plus">
                                </el-button>
                            </el-popover>
                        </template>

                    </el-table-column>
                    
                    <el-table-column                  
                    header-align="center"                 
                    label="操作"
                    width="100">
                    <template slot-scope="scope">
                        <div style="display:flex">
                        <!-- <el-button title="编辑" type="primary" size="mini" icon="el-icon-edit" circle @click="fujue(scope.row)"></el-button> -->
                        <el-popconfirm                             
                            title="您确定删除吗？"
                            @confirm="isDelete(scope.row.id)">
                            <el-button title="删除" slot="reference" :disabled="scope.row.userQq==2415648320"  type="danger" size="mini" icon="el-icon-delete">删除</el-button>
                        </el-popconfirm>
                        </div>
                        
                    </template>
                    </el-table-column>
                </el-table>
            </template>
        </div>

        <el-dialog
            title="新增用户"
            :visible.sync="centerDialogVisible">
            <el-form :model="userForm" status-icon :rules="rules" ref="userForm" label-width="100px" class="demo-userForm">
                <el-form-item label="头像" prop="avatar">
                    <img style="height: 80px;" :src="'https://q1.qlogo.cn/headimg_dl?dst_uin='+userForm.userQq+'&spec=100'">
                </el-form-item>
                
                <el-form-item label="Q Q" prop="userQq">
                    <el-input v-model.number="userForm.userQq"></el-input>
                </el-form-item>
                <el-form-item label="昵称" prop="nickname" :rules="{
                required: true, message: '不能为空', trigger: 'blur'
                }">
                    <el-input v-model="userForm.nickname" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="用户名" prop="username" :rules="[
                        { required: true, message: '用户名不能为空'},
                        // {type:'',message: '用户名必须为数字或者字母'},
                        {pattern: /^[A-Za-z0-9]+$/,message: '只能包含字母、数字',trigger: 'blur'}
                        // { type: 'number', message: '年龄必须为数字值'}
                        ]">
                    <el-input v-model="userForm.username" autocomplete="off"></el-input>
                </el-form-item>
                
                <el-form-item label="密码" prop="password">
                    <el-input type="password" v-model="userForm.password" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="checkPassword">
                    <el-input type="password" v-model="userForm.checkPassword" autocomplete="off"></el-input>
                </el-form-item>
              
                <el-form-item>
                    <el-button size="small" @click="centerDialogVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="successXinzeng()">确认添加</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import { addUser, deleteUser, rList, uList, userAddRoles, userDeleteRoles } from '@/api/userRoleP';

export default {
    data(){

        var checkAge = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('QQ不能为空'));
            }
            setTimeout(() => {
            if (!Number.isInteger(value)) {
                callback(new Error('请输入数字值'));
            } 
            }, 1000);
        };
        var validatePass = (rule, value, callback) => {

            if (value === '') {
                callback(new Error('请输入密码'));
            } else {
            if (this.userForm.checkPassword !== '') {
                this.$refs.userForm.validateField('checkPassword'); 
            }
                callback();
            }
        };
        var validatePass2 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请再次输入密码'));
            } else if (value !== this.userForm.password) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        };
   
        return{
            tableData:[],
            centerDialogVisible:false,
            roleList:[],
            // 确认密码
            // checkPassword:'',
            userForm:{
                nickname: "",
                username: "",
                password: "",
                checkPassword:'',
                avatar: "",
                userQq : "",
                isDisable: 0,
            },

            rules: {
                password: [
                    {required: true, validator: validatePass, trigger: 'blur' }
                ],
                checkPassword: [
                    {required: true,validator: validatePass2, trigger: 'blur' }
                ],
                userQq: [
                    {required: true, validator: checkAge, trigger: 'blur' }
                ]
            }
        }
    },
    methods:{
        getUserListAndRoleList(){
            uList().then((res)=>{
                //console.log(res.data)
                 this.tableData=res.data
            })

            rList().then((res)=>{
                this.roleList=res.data
            })
        },

        xinzeng(){
            this.centerDialogVisible=true
        },
        
        successXinzeng() {
            console.log(this.userForm)  
            addUser(this.userForm).then((res)=>{
                if(res.code==200){
                    this.$message({
                        message:res.message,
                        type: 'success',
                        duration:1000,                       
                    });  
                    this.centerDialogVisible=false
                    this.getUserListAndRoleList()
                }else{
                    this.$message({
                        message:res.message,
                        type: 'error',
                        duration:1000,
                    });
                } 
            })
        },
        

        // 删除赋角
        handleClose(u_id,r_id){
            userDeleteRoles({userId:u_id,roleId:r_id}).then((res)=>{
                if(res.code==200){
                    this.$message({
                        message:res.message,
                        type: 'success',
                        duration:1000,                       
                    });  
                    this.getUserListAndRoleList()
                }else{
                    this.$message({
                        message:res.message,
                        type: 'error',
                        duration:1000,
                    });
                } 
            })
        },
        // 授予角色
        addro(r_id,u_id){
            userAddRoles({userId:u_id,roleId:r_id}).then((res)=>{
                if(res.code==200){
                    this.$message({
                        message:res.message,
                        type: 'success',
                        duration:1000,                       
                    });  
                    this.getUserListAndRoleList()
                }else{
                    this.$message({
                        message:res.message,
                        type: 'error',
                        duration:1000,
                    });
                } 
            })
        },
        // 判断当前角色
        roleClass(role_id,roles){  
            const rolesIdList=[]
            for (let i = 0; i < roles.length; i++) {
                rolesIdList[i]=roles[i].id
            }
            const index = rolesIdList.indexOf(role_id);   
            // console.log("index",index)  
            return index !== -1 ? true :false;   
        },

        isDelete(id){
            // console.log(id)
            deleteUser(id).then((res)=>{
                if(res.code==200){
                    this.$message({
                        message:res.message,
                        type: 'success',
                        duration:1000,                       
                    });  
                    this.getUserListAndRoleList()
                }else{
                    this.$message({
                        message:res.message,
                        type: 'error',
                        duration:1000,
                    });
                } 
            })
        }
    },
    created(){
        this.getUserListAndRoleList()
        
    }
}
</script>

<style>

</style>