import { SlateTransforms ,SlateEditor, SlateElement, SlateNode} from '@wangeditor/editor'
class MyIndent {                       // JS 语法
    constructor(editor) {
      this.title = '缩进',
      this.tag = 'select'
      this.width = 80
    }
    // 下拉框的选项
    getOptions(editor) {            // JS 语法
        const options =[
            // { value: 'suojin', text: '缩进' },
            { value: 'addIndent', text: '增加缩进' },
            { value: 'removeIndent', text: '减少缩进' },
        ]
        return options
    }
    // 菜单是否需要激活（如选中加粗文本，“加粗”菜单会激活），用不到则返回 false
    isActive(editor) {                      // JS 语法
        return false
    }
    // 获取菜单执行时的 value ，用不到则返回空 字符串或 false
    getValue(editor) {                               // JS 语法
        return 'addIndent' // 匹配 options 其中一个 value
    }
    // 菜单是否需要禁用（如选中 H1 ，“引用”菜单被禁用），用不到则返回 false
    isDisabled(editor) {                     // JS 语法
        return false
    }
    // 点击菜单时触发的函数
    exec(editor, value) {                              // JS 语法
        console.log('2222222',editor, value,this);
        const nodeEntries = SlateEditor.nodes(editor, {
            // match: (node: SlateNode) => {  // TS syntax
            match: (node) => {          // JS syntax
                if (SlateElement.isElement(node)) {
                    if (node.type === 'paragraph') {
                        return true // 匹配 paragraph
                    }
                }
                return false
            },
            universal: true,
        })
        if (nodeEntries == null) {
            console.log('当前未选中的 paragraph')
        } else {
            for (let nodeEntry of nodeEntries) {
                const [node, path] = nodeEntry

                console.log("我是const [node, path]",node,path)

                let indent = node.indent
                let setIndent
                //增加缩进
                if(value=='addIndent'){
                    if(!indent){
                        setIndent='2em'
                    }else{
                        // parseInt四舍五入，substring()截取字符串
                        setIndent=(parseInt(indent.substring(0,indent.length-2))+2)+'em'
                    }
                    SlateTransforms.setNodes(editor, {
                        indent: setIndent
                    }, {
                    })
                }else{
                    //减少缩进
                    if(indent=='0em'){
                        return
                    }else{
                        setIndent=(parseInt(indent.substring(0,indent.length-2))-2)+'em'
                    }
                    SlateTransforms.setNodes(editor, {
                        indent: setIndent
                    }, {
                    })
                }             
            }
        }       
    }
}

class MyBlockquote{
    constructor(editor) {
        this.title = '我的引用',     
        this.tag = 'select'
        this.width = 80
        // this.iconSvg='<svg t="1716967428886" class="icon" viewBox="0 0 1068 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8671" width="64" height="64"><path d="M44.383156 257.841727H44.201439v201.36211h201.36211v-201.36211H167.209132C171.044835 190.847079 226.738647 137.515588 294.676259 137.515588V14.733813C227.770014 14.733813 164.871367 40.788106 117.566005 88.098379 71.999233 133.66024 46.224882 193.700528 44.383156 257.841727zM564.796163 137.515588V14.733813c-66.906245 0-129.804892 26.054293-177.110254 73.364566C342.119137 133.66024 316.344787 193.700528 314.50306 257.841727H314.321343v201.36211h201.36211v-201.36211H437.329036C441.159827 190.847079 496.863463 137.515588 564.796163 137.515588zM1055.746456 739.146283H1055.923261v-201.36211h-201.36211v201.36211h78.354417c-3.830791 66.994647-59.529516 120.326139-127.467127 120.326139v122.781775c66.906245 0 129.814715-26.054293 177.115166-73.364566 45.566772-45.561861 71.341122-105.597237 73.182849-169.743348zM535.328537 859.472422v122.781775c66.906245 0 129.804892-26.054293 177.115166-73.364566 45.561861-45.561861 71.336211-105.611971 73.177937-169.748259H785.803357v-201.362111h-201.36211v201.362111h78.349506C658.964873 806.14093 603.261237 859.472422 535.328537 859.472422z" fill="#00CCC6" p-id="8672"></path></svg>'
    }
    // 下拉框的选项
    getOptions(editor) {            // JS 语法
          const options =[
            { value: 'moren', text: '默认引用'},
            { value: 'blue', text: '蓝色'},
            { value: 'black', text: '深色' },
            { value: 'red', text: '红色' },
            { value: 'green', text: '绿色' },
            { value: 'red', text: '红色' },
            { value: 'purple', text: '紫色' },
            { value: 'orange', text: '橙色' },
          ]
          return options
      }
      // 菜单是否需要激活（如选中加粗文本，“加粗”菜单会激活），用不到则返回 false
      isActive(editor) {                      // JS 语法
          return false
      }
      // 获取菜单执行时的 value ，用不到则返回空 字符串或 false
      getValue() {                               // JS 语法
          return 'moren' // 匹配 options 其中一个 value
      }
      // 菜单是否需要禁用（如选中 H1 ，“引用”菜单被禁用），用不到则返回 false
      isDisabled(editor) {                     // JS 语法
          return false
      }
      getPanelContentElem(editor){

      }
      exec(editor, value) {  
        const nodeEntries = SlateEditor.nodes(editor, {
            // match: (node: SlateNode) => {  // TS syntax
            match: (node) => {          // JS syntax
                if (SlateElement.isElement(node)) {
                    if (node.type === 'blockquote') {
                        return true // 匹配 paragraph
                    }
                }
                return false
            },
            universal: true,
        })
        if (nodeEntries == null) {
            console.log('当前未选中的 blockquote')
        } else {
            for (let nodeEntry of nodeEntries) {
                const [node, path] = nodeEntry

                console.log("我是const [node, path]",node,path)
             
                SlateTransforms.setNodes(editor, {
                    isColor: value
                }, {
                })                         
                
            }       
        } 
    }
    
}


// config: {  
//     insertField: getinsertField()
// }
const myMenuIndent = {
    key: 'myMenuIndent', // 定义 menu key ：要保证唯一、不重复（重要）
    factory() {
        return new MyIndent() // 把 `YourMenuClass` 替换为你菜单的 class
    },
}
const myMenuBlockquote = {
    key: 'myMenuBlockquote', // 定义 menu key ：要保证唯一、不重复（重要）
    factory() {
      return new MyBlockquote() // 把 `YourMenuClass` 替换为你菜单的 class
    },
  }
 

export  {myMenuIndent,myMenuBlockquote}