<template>
    <div style="padding: 10px;">
       
        <index-one></index-one>
        
    </div>
</template>

<script>
import IndexOne from '@/components/index/IndexOne.vue'
export default {
  components: { IndexOne },
    name:"AdminIndex"
}
</script>

<style>

</style>