<template>
  <div id="app">  
    <router-view></router-view>
  </div>
</template>

<script>
// import AdminIndex from './layout/AdminIndex'


export default {
  name: 'App',
  components: {
    // AdminIndex
  }
}
</script>

<style>
.fullscreen-editor{
  position:fixed;
  top: 0;
  left:  0;
  padding: 0 !important;
  margin: 0 !important;
  height: 100vh !important;
  width: 100%;
  z-index: 9999; /* 设置高层级 */
}
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */

body{
  overflow-y: auto !important;
  padding-right: 0 !important;
}
html,
body,
div{
margin: 0;
padding: 0;
}
/* 滚动条样式 */
::-webkit-scrollbar{
    width: 5px;
    height: 8px;
    /* background-color: rgba(43, 43, 43, 0.747);  */
}
::-webkit-scrollbar-thumb{
    border-radius: 5px;
    background-color: rgba(197, 197, 197, 0.829);
}
</style>
