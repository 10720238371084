import Vue from 'vue'
import App from './App.vue'
import store from './store'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

// highlight.js代码高亮指令
// import Highlight from '@/utils/highlight';
// 在main.js 引入css
import 'highlight.js/styles/monokai-sublime.css'
// 引入插件
import VueRouter from 'vue-router'
// 引入路由器
import router from './router'

import { files,qiNiuLink } from '@/utils/config.js';
// 全局方法挂载
Vue.prototype.files = files;
Vue.prototype.qiNiuLink = qiNiuLink;



Vue.config.productionTip = false
// 应用
Vue.use(VueRouter)
Vue.use(ElementUI);
// Vue.use(Highlight)


new Vue({
  beforeCreate(){
    Vue.prototype.$bus=this
  },
  render: h => h(App),
  router:router,
  store
}).$mount('#app')
