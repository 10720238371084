<template>
    <div class="web-setting">
        <!-- 公告： -->
        <el-card class="box-card" style="margin: 20px;position: relative;">
            <span style="font-size: 13px;">网站公告</span> 
            <el-button type="primary" size="mini" style="position: absolute;right: 20px;top: 15px;" @click="successWebNotice">发布公告</el-button>
            <div class="a-notice" style="border: 1px solid #ccc;margin-top: 8px;">
                
                    <!-- 工具栏 -->
                <Toolbar style="border-bottom: 1px solid #ccc" :editor="editor" :defaultConfig="toolbarConfig" />
                <div class="notice-leftandright">
                    <!-- 编辑器 -->
                    <div class="a-notice-left a-notice-zi">                 
                        <Editor style="height: 100%; overflow-y: hidden" :defaultConfig="editorConfig" v-model="noticeHtml"
                        @onChange="onChange" @onCreated="onCreated" />
                    </div>
                    <div class="a-notice-right a-notice-zi">
                        <div v-html="noticeHtml">

                        </div>
                    </div>
                </div>
            </div>
        </el-card>

        <div style="display: flex;flex-wrap: wrap;margin: 0 10px;">
            <el-card class="setting-zi-box">
                <el-form ref="form" :model="NameorThemeorSignatureForm" label-width="80px">
                    <el-form-item label="网站名称">
                        <el-input size="small" v-model="NameorThemeorSignatureForm.webName"></el-input>
                    </el-form-item>
                    <el-form-item label="网站签名">
                        <el-input type="textarea" 
                        :rows="2" 
                        v-model="NameorThemeorSignatureForm.webSignature"></el-input>
                    </el-form-item>
                    <el-form-item label="主题颜色">
                        <!-- <el-select v-model="value" placeholder="请选择">
                            <el-option
                            v-for="item in themeColorList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select> -->
                        <el-color-picker v-model="NameorThemeorSignatureForm.themeColor"></el-color-picker>
                    </el-form-item>
                    <el-button type="primary" size="mini" style="margin-left: 10px;" @click="updateNameorThemeorSignature">确认修改</el-button>
                </el-form>
            </el-card>

            <el-card class="setting-zi-box">
                博客背景
                <div style="font-size: 10px; font-weight: 100; color: rgb(83, 83, 83);letter-spacing: 1px;position: absolute;right: 20px;">点击图片可进行更改！</div>
                <el-upload
                    class="avatar-uploader"
                    action="string"
                    :show-file-list="false"
                    :http-request="uploadImageA"
                    :before-upload="beforeAvatarUpload">
                    <img v-if="imageUrlA" :src="imageUrlA" style="width: 100%;">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
            </el-card>

            <el-card class="setting-zi-box">
                相册顶部背景
                <div style="font-size: 10px; font-weight: 100; color: rgb(83, 83, 83);letter-spacing: 1px;position: absolute;right: 20px;">点击图片可进行更改！</div>
                <el-upload
                    class="avatar-uploader"
                    action="string"
                    :show-file-list="false"
                    :http-request="uploadImageB"
                    :before-upload="beforeAvatarUpload">
                    <img v-if="imageUrlB" :src="imageUrlB" style="width: 100%;">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
            </el-card>

            <el-card class="setting-zi-box">
                随笔顶部背景
                <div style="font-size: 10px; font-weight: 100; color: rgb(83, 83, 83);letter-spacing: 1px;position: absolute;right: 20px;">点击图片可进行更改！</div>
                <el-upload
                    class="avatar-uploader"
                    action="string"
                    :show-file-list="false"
                    :http-request="uploadImageC"
                    :before-upload="beforeAvatarUpload">
                    <img v-if="imageUrlC" :src="imageUrlC" style="width: 100%;">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
            </el-card >

            <el-card class="setting-zi-box">
                留言顶部背景
                <div style="font-size: 10px; font-weight: 100; color: rgb(83, 83, 83);letter-spacing: 1px;position: absolute;right: 20px;">点击图片可进行更改！</div>
                <el-upload
                    class="avatar-uploader"
                    action="string"
                    :show-file-list="false"
                    :http-request="uploadImageD"
                    :before-upload="beforeAvatarUpload">
                    <img v-if="imageUrlD" :src="imageUrlD" style="width: 100%;">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
            </el-card >

            <el-card class="setting-zi-box">
                友链顶部背景
                <div style="font-size: 10px; font-weight: 100; color: rgb(83, 83, 83);letter-spacing: 1px;position: absolute;right: 20px;">点击图片可进行更改！</div>
                <el-upload
                    class="avatar-uploader"
                    action="string"
                    :show-file-list="false"
                    :http-request="uploadImageE"
                    :before-upload="beforeAvatarUpload">
                    <img v-if="imageUrlE" :src="imageUrlE" style="width: 100%;">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
            </el-card >

            <el-card class="setting-zi-box">
                工具顶部背景
                <div style="font-size: 10px; font-weight: 100; color: rgb(83, 83, 83);letter-spacing: 1px;position: absolute;right: 20px;">点击图片可进行更改！</div>
                <el-upload
                    class="avatar-uploader"
                    action="string"
                    :show-file-list="false"
                    :http-request="uploadImageF"
                    :before-upload="beforeAvatarUpload">
                    <img v-if="imageUrlF" :src="imageUrlF" style="width: 100%;">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
            </el-card >
        </div>
        
        
    </div>
</template>

<script>
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import { DomEditor } from "@wangeditor/editor";
import { shangchuanImages } from '../api/uplod'
import { getWeb, updateOther, updateHomebgi, updateLybgi, updateSbbgi, updateWebNotice, updateWebXcbgi, updateFriendbgi,updateToolbgi } from '@/api/setting';

export default {
    name:'WebSetting',
    components: { Editor, Toolbar},
    data() {  
        return {
            zonData:'',
            editor: null,
            noticeHtml: '',
            placeholder: null, // 富文本占位内容
            // height: '300px', // 富文本最小高度
            toolbarConfig: {
                // toolbarKeys: [ /* 显示哪些菜单，如何排序、分组 */ ],
                // excludeKeys: [  /* 隐藏哪些菜单 */ ],
            },
            editorConfig: {
                placeholder: "暂无公告",
            },     
            webform:'',
            NameorThemeorSignatureForm: {
                webName:'',
                // // webNotice:'',
                webSignature:'',
                themeColor:''
                // webName:'',
                // webSignature:''
            },

            // 上传图片回显
            imageUrlA:'',
            imageUrlB:'',
            imageUrlC:'',
            imageUrlD:'',
            imageUrlE:'',
            imageUrlF:'',
        };
    },
    methods:{
        getWebSetting(){
            getWeb().then((res)=>{
                this.webform=res.data
                // console.log(this.webform)

                this.NameorThemeorSignatureForm={
                    webName:this.webform.webName,
                    // webNotice:this.webform.webNotice,
                    webSignature:this.webform.webSignature,
                    themeColor:this.webform.themeColor
                }
                this.noticeHtml=this.webform.webNotice,
                this.imageUrlA=this.files.myblogImageUrl+this.webform.webHomeImage
                this.imageUrlB=this.files.myblogImageUrl+this.webform.webXcbgi
                this.imageUrlC=this.files.myblogImageUrl+this.webform.webSbbgi
                this.imageUrlD=this.files.myblogImageUrl+this.webform.webLybgi
                this.imageUrlE=this.files.myblogImageUrl+this.webform.webFriendbgi
                this.imageUrlF=this.files.myblogImageUrl+this.webform.webToolbgi
            })
        },

        onCreated(editor) {
        this.editor = Object.seal(editor); // 【注意】一定要用 Object.seal() 否则会报错
        this.toolbarConfig = {
                excludeKeys: [
                    "insertTable", // 插入表格                           
                    "codeBlock",
                    "group-image",
                    'group-video',
                    "fullScreen",
                    "todo", // 待办   
                    "divider", // 分割线
                    "blockquote", // 引用
                ]
            }
        },
        
        onChange(editor) {
        // onChange 时获取编辑器最新内容
            const toolbar = DomEditor.getToolbar(editor)
            //   console.log("工具栏配置", toolbar.getConfig().toolbarKeys); // 工具栏配置
        
        },
        
        // 上传前判断
        beforeAvatarUpload(file) {
            const isjpg = file.type === 'image/jpg';
            const isjpeg = file.type === 'image/jpeg';
            const isgif = file.type === 'image/gif';
            const ispng = file.type === 'image/png';
            const isJPG = file.type === 'image/JPG';
            const isJPEG = file.type === 'image/JPEG';
            const isGIF = file.type === 'image/GIF';
            const isPNG = file.type === 'image/PNG';
            if (!(isJPG || isJPEG || isGIF || isPNG || isjpg || isjpeg || isgif || ispng)) {
                this.$message.error('只能上传JPG 、JPEG 、GIF、 PNG格式的图片!');
            }
            const isLt2M = file.size / 1024 / 1024 < 10;
            if (!isLt2M) {
                this.$message.error('上传单张图片大小不能超过10MB!');
            }
            return (isJPG || isJPEG || isGIF || isPNG || isjpg || isjpeg || isgif || ispng) && isLt2M;
        } ,

        uploadImageA(param){
            const formData=new FormData()
            formData.append('file',param.file)
            // formData.append('id',2)
            shangchuanImages(formData).then((res)=>{
            // this.$message({
            //     message:res.message,
            //     type: 'success',
            //     duration:1000,
            //     });
                if(res.code==200){
                    this.imageUrlA=this.files.myblogImageUrl+res.data
                    updateHomebgi(res.data).then((res1)=>{              
                        if(res1.code==200){
                            this.$message({
                                message:res1.message,
                                type: 'success',
                                duration:1000,                  
                            });
                        }                     
                    })  
                }else{
                    this.$message({
                        message:res.message,
                        type: 'error',
                        duration:1000,                  
                    });
                } 
            })
        },

        uploadImageB(param){
            const formData=new FormData()
            formData.append('file',param.file)
            // formData.append('id',2)
            shangchuanImages(formData).then((res)=>{
                if(res.code==200){
                    this.imageUrlB=this.files.myblogImageUrl+res.data
                    updateWebXcbgi(res.data).then((res1)=>{
                        this.$message({
                        message:res1.message,
                        type: 'success',
                        duration:1000,                  
                        });
                    })  
                }else{
                    this.$message({
                        message:res.message,
                        type: 'error',
                        duration:1000,                  
                    });
                }
                // this.suibiForm.suibiImg=res.data
                // console.log(this.imageUrl)
            })
        },

        uploadImageC(param){
            const formData=new FormData()
            formData.append('file',param.file)
            // formData.append('id',2)
            shangchuanImages(formData).then((res)=>{
                // console.log(res)
                if(res.code==200){
                    this.imageUrlC=this.files.myblogImageUrl+res.data
                    updateSbbgi(res.data).then((res1)=>{
                        this.$message({
                        message:res1.message,
                        type: 'success',
                        duration:1000,                  
                        });
                    })  
                }else{
                    this.$message({
                        message:res.message,
                        type: 'error',
                        duration:1000,                  
                    });
                } 
            })
        },

        uploadImageD(param){
            const formData=new FormData()
            formData.append('file',param.file)
            // formData.append('id',2)
            shangchuanImages(formData)
            .then((res)=>{
            // this.$message({
            //     message:res.message,
            //     type: 'success',
            //     duration:1000,
            //     });
            //     this.imageUrlD=this.files.myblogImageUrl+res.data
            //     // this.suibiForm.suibiImg=res.data
            //     console.log(this.imageUrl)
                if(res.code==200){
                    this.imageUrlD=this.files.myblogImageUrl+res.data
                    updateLybgi(res.data).then((res1)=>{
                        this.$message({
                        message:res1.message,
                        type: 'success',
                        duration:1000,                  
                        });
                    })  
                }else{
                    this.$message({
                        message:res.message,
                        type: 'error',
                        duration:1000,                  
                    });
                } 
            })
        },

        uploadImageE(param){
            const formData=new FormData()
            formData.append('file',param.file)
            // formData.append('id',2)
            shangchuanImages(formData)
            .then((res)=>{
            // this.$message({
            //     message:res.message,
            //     type: 'success',
            //     duration:1000,
            //     });
            //     this.imageUrlD=this.files.myblogImageUrl+res.data
            //     // this.suibiForm.suibiImg=res.data
            //     console.log(this.imageUrl)
                if(res.code==200){
                    this.imageUrlE=this.files.myblogImageUrl+res.data
                    updateFriendbgi(res.data).then((res1)=>{
                        this.$message({
                        message:res1.message,
                        type: 'success',
                        duration:1000,                  
                        });
                    })  
                }else{
                    this.$message({
                        message:res.message,
                        type: 'error',
                        duration:1000,                  
                    });
                } 
            })
        },

        uploadImageF(param){
            const formData=new FormData()
            formData.append('file',param.file)
            // formData.append('id',2)
            shangchuanImages(formData)
            .then((res)=>{
            // this.$message({
            //     message:res.message,
            //     type: 'success',
            //     duration:1000,
            //     });
            //     this.imageUrlD=this.files.myblogImageUrl+res.data
            //     // this.suibiForm.suibiImg=res.data
            //     console.log(this.imageUrl)
                if(res.code==200){
                    this.imageUrlF=this.files.myblogImageUrl+res.data
                    updateToolbgi(res.data).then((res1)=>{
                        this.$message({
                        message:res1.message,
                        type: 'success',
                        duration:1000,                  
                        });
                    })  
                }else{
                    this.$message({
                        message:res.message,
                        type: 'error',
                        duration:1000,                  
                    });
                } 
            })
        },

        successWebNotice(){
            updateWebNotice({id:this.webform.id,webNotice: this.noticeHtml}).then((res)=>{
                if(res.code==200){
                    this.$message({
                        message:res.message,
                        type: 'success',
                        duration:1000,
                    });  
                    this.getWebSetting()
                }else{
                        this.$message({
                        message:res.message,
                        type: 'error',
                        duration:1000,
                    });
                } 
            })
        },

        updateNameorThemeorSignature(){
            updateOther(this.NameorThemeorSignatureForm).then((res)=>{
                if(res.code==200){
                    this.$message({
                        message:res.message,
                        type: 'success',
                        duration:1000,
                    });  
                    this.getWebSetting()
                }else{
                        this.$message({
                        message:res.message,
                        type: 'error',
                        duration:1000,
                    });
                } 
            })
        }
        
    
    },

    created(){
        this.getWebSetting()
        // console.log(this.files.myblogImageUrl)
    },

    beforeDestroy() {
        const editor = this.editor;
        if (editor == null) return;
        editor.destroy(); // 组件销毁时，及时销毁 editor ，重要！！！
    },
}
</script>

<style>
/* .web-setting{

}
.a-notice{

} */
.notice-leftandright{
    display: flex;
}
.a-notice-right{
    border-left: 1px solid #ccc;
    height: 300px;
    padding: 10px;
}
.a-notice-zi{
    width: 50%;
}
.setting-zi-box{
    width:  calc(100% / 4 - 22px);
    margin: 10px;
    position: relative;
    /* max-height: 240px; */
}
.setting-zi-box .el-upload {
    margin-top: 20px;
    width: 99%;
    height:100% ;
}
.setting-zi-box .el-upload, .el-upload-list--picture-card i {
    line-height: 0;
}
@media (max-width:1400px) {	
    .setting-zi-box{
        width:  calc(100% / 3 - 22px);
    }
}
@media (max-width:1100px) {	
    .setting-zi-box{
        width:  calc(100% / 2 - 22px);
    }
}
@media (max-width:750px) {	
    .setting-zi-box{
        width:  100%
    }
    .notice-leftandright{
        display: inline;
    }
    .a-notice-zi{
        width: 100%;
        height: 200px;
    }
    .a-notice-right{
        border-top: 1px solid #ccc;
    }
}
</style>