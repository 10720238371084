<template>
    <div>
        <div style="padding: 10px 10px 0 10px;display:flex">
            <el-button type="primary" size="mini" plain @click="add">新增</el-button>

            <div style="display: flex;margin-left:20px">
              <el-input
                placeholder="请输入搜索内容"
                v-model="search"
                size="small"
                prefix-icon="el-icon-search"
                @keyup.enter.native="searchToolList()"
                clearable>
              </el-input>
              <el-button 
                style="margin-left:10px" 
                size="small" 
                @click="searchToolList()"
                type="primary">
                搜索
              </el-button>
            </div>
        </div>

        <div class="articlelist-table">
            <template>
                <el-table
                    :data="tableData.slice((pageNum - 1) * pageSize, pageNum * pageSize)"
                    :border="true"
                    :default-sort = "{prop: 'date', order: 'descending'}"
                    >
                    <el-table-column
                    header-align="center"
                    prop="createTime"
                    label="创建时间"
                    sortable
                    width="150">
                    </el-table-column>

                    <el-table-column
                    header-align="center"
                    prop="toolName"
                    label="名称"
                    width="140">
                    </el-table-column>

                    <el-table-column
                    header-align="center"
                    prop="toolLogo"
                    label="logo"
                    width="140">
                        <template slot-scope="scope">
                            <img v-if="!scope.row.toolLogo" style="height:50px" src="../assets/images/noImage.png">
                            <img v-else style="height:50px" :src="files.myblogImageUrl+scope.row.toolLogo" alt="">                           
                        </template>
                    </el-table-column>

                    <el-table-column
                    header-align="center"
                    prop="toolLink"
                    label="链接"
                    >
                    </el-table-column>

                    <el-table-column
                    header-align="center"
                    prop="toolIntroduce"
                    label="描述"
                    >
                    </el-table-column>

                    <el-table-column
                    header-align="center"
                    prop="type"
                    label="分类"
                    width="">
                        <template slot-scope="scope">
                            <el-tag>{{ ifType(scope.row.type) }}</el-tag>
                        </template>
                    </el-table-column>
                                 
                    <el-table-column
                        header-align="center"
                        prop="ifDelete"
                        label="是否禁用"
                        width="100">
                          <template slot-scope="scope">
                            <el-switch v-model="scope.row.ifDelete" active-color="#13ce66"  @change="isDisable(scope.row)"
                             :active-value="1" :inactive-value="0">
                            </el-switch>
                          </template>
                    </el-table-column>

                    <el-table-column                  
                    header-align="center"                 
                    label="管理"
                    width="100">
                    <template slot-scope="scope">
                  
                        <el-button style="margin-bottom:5px" type="primary" size="mini" icon="el-icon-edit"  @click="isbianji(scope.row)">编辑</el-button>
                     
                        <el-popconfirm                             
                            title="您确定删除吗？"
                            @confirm="isDelete(scope.row.id)">
                            <el-button title="删除" slot="reference"  type="danger" size="mini" icon="el-icon-delete" >删除</el-button>
                        </el-popconfirm>                                              
                    </template>
                    </el-table-column>
                </el-table>
            </template>
        </div>
        <div style="padding: 10px;">
          <el-pagination  
            :current-page="pageNum"
            :page-sizes="[5, 10, 20, 30]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="tableData.length"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange">
          </el-pagination>
        </div>

        <el-dialog
            title="新增"
            :visible.sync="centerDialogVisible">
            <el-form :model="toolForm">
                <el-form-item label="名称" :label-width="'80px'">
                    <el-input v-model="toolForm.toolName" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="链接" :label-width="'80px'">
                    <el-input v-model="toolForm.toolLink" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="描述" :label-width="'80px'">
                    <el-input v-model="toolForm.toolIntroduce" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="logo" :label-width="'80px'">
                    <el-upload                      
                        class="avatar-uploader"
                        action="string"
                        :show-file-list="false"
                        :http-request="uploadImage"
                        :before-upload="beforeAvatarUpload">
                        <img v-if="imageUrl" :src="imageUrl" style="height: 110px;">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>

                    <!-- <el-input v-model="toolForm.toolLogo" autocomplete="off"></el-input> -->
                </el-form-item>
                <el-form-item label="类型" :label-width="'80px'">
                    <template>
                        <el-select v-model="toolForm.type" placeholder="请选择" size="small">
                            <el-option
                            v-for="(t,i) in typeList" 
                            :key="i"
                            :label="t.typeName"
                            :value="t.id">
                            </el-option>
                        </el-select>
                    </template>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="centerDialogVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="successAdd">新 增</el-button>
            </span>
        </el-dialog>

        <el-dialog
            title="编辑"
            :visible.sync="centerDialogVisible1">
            <el-form :model="toolForm">
                <el-form-item label="名称" :label-width="'80px'">
                    <el-input v-model="toolForm.toolName" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="链接" :label-width="'80px'">
                    <el-input v-model="toolForm.toolLink" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="描述" :label-width="'80px'">
                    <el-input v-model="toolForm.toolIntroduce" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="logo" :label-width="'80px'">
                    <el-upload                      
                        class="avatar-uploader"
                        action="string"
                        :show-file-list="false"
                        :http-request="uploadImage"
                        :before-upload="beforeAvatarUpload">
                        <img v-if="imageUrl" :src="imageUrl" style="height: 110px;">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                    <!-- <el-input v-model="toolForm.toolLogo" autocomplete="off"></el-input> -->
                </el-form-item>
                <el-form-item label="类型" :label-width="'80px'">
                    <template>
                        <el-select v-model="toolForm.type" placeholder="请选择" size="small">
                            <el-option
                            v-for="(t,i) in typeList" 
                            :key="i"
                            :label="t.typeName"
                            :value="t.id">
                            </el-option>
                        </el-select>
                    </template>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="centerDialogVisible1 = false">取 消</el-button>
                <el-button size="small" type="primary" @click="successBianji">完成编辑</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import {addTool, toolList,updateTool,deleteTool, toolTypeInTool} from '@/api/tool'
import { shangchuanImages } from '../api/uplod'
export default {
    name:'AdminTool',
    data(){
        return{
            search:'',
            imageUrl:'',

            tableData:[],
            pageNum:1,
            pageSize:20,
            centerDialogVisible:false,
            centerDialogVisible1:false,

            typeList:[],
            typeNumList:[],

            toolForm:{
                toolName:'',
                toolLink:'',
                toolIntroduce:'',
                toolLogo:'',
                type:''
            }
        }
    },
    methods:{
        list(){
            toolList(this.search).then((res)=>{
                this.tableData=res.data
            })
        },
        getTypeList(){
            toolTypeInTool().then((res)=>{
                this.typeList=res.data
                const s=[]
                for (let i = 0; i < this.typeList.length; i++) {
                    s[i]=this.typeList[i].id
                }
                this.typeNumList=s
            })
        },
        searchToolList(){
            toolList(this.search).then((res)=>{
                this.tableData=res.data
            })
        },
        ifType(id){
            return this.typeList[this.typeNumList.indexOf(id)].typeName
        },
        add(){
            this.centerDialogVisible=true
            this.toolForm={
                toolName:'',
                toolLink:'',
                toolIntroduce:'',
                toolLogo:'',
                type:''
            }
            this.imageUrl=''
        },  
        // 上传前判断
        beforeAvatarUpload(file) {
            const isjpg = file.type === 'image/jpg';
                const isjpeg = file.type === 'image/jpeg';
                const isgif = file.type === 'image/gif';
                const ispng = file.type === 'image/png';
                const isJPG = file.type === 'image/JPG';
                const isJPEG = file.type === 'image/JPEG';
                const isGIF = file.type === 'image/GIF';
                const isPNG = file.type === 'image/PNG';
                if (!(isJPG || isJPEG || isGIF || isPNG || isjpg || isjpeg || isgif || ispng)) {
                    this.$message.error('只能上传JPG 、JPEG 、GIF、 PNG格式的图片!');
                }

            const isLt2M = file.size / 1024 / 1024 < 10;
                if (!isLt2M) {
                    this.$message.error('上传单张图片大小不能超过10MB!');
                }
                return (isJPG || isJPEG || isGIF || isPNG || isjpg || isjpeg || isgif || ispng) && isLt2M;
        } ,
        // 图片上传
        uploadImage(param){
            const formData=new FormData()
            formData.append('file',param.file)
            // formData.append('id',2)
            shangchuanImages(formData)
            .then((res)=>{
            this.$message({
                message:res.message,
                type: 'success',
                duration:1000,
                });
            this.imageUrl=this.files.myblogImageUrl+res.data
            this.toolForm.toolLogo=res.data
            // console.log(this.imageUrl)
            })

        },    
        successAdd(){    
            // console.log(this.toolForm)
            if(!this.toolForm.toolName){
                return this.$message({
                    message: '名称不能为空',
                    type: 'warning'
                });
            }
            if(!this.toolForm.toolLink){
                return this.$message({
                    message: '链接不能为空',
                    type: 'warning'
                });
            }
            if(!this.toolForm.type){
                return this.$message({
                    message: '类型不能为空',
                    type: 'warning'
                });
            }
            addTool(this.toolForm).then((res)=>{
                if(res.code==200){
                    this.$message({
                        message:res.message,
                        type: 'success',
                        duration:1000,                       
                    });  
                    this.centerDialogVisible=false
                    this.searchToolList()
                }else{
                    this.$message({
                        message:res.message,
                        type: 'error',
                        duration:1000,
                    });
                } 
            })
        },
        isDisable(row){
            // console.log(row)
            updateTool(row).then((res)=>{
                if(res.code==200){
                    this.$message({
                        message:res.message,
                        type: 'success',
                        duration:1000,                       
                    });  
                    this.searchToolList()
                }else{
                    this.$message({
                        message:res.message,
                        type: 'error',
                        duration:1000,
                    });
                } 
            })
        },

        isDelete(id){
            deleteTool(id).then((res)=>{
                if(res.code==200){
                    this.$message({
                        message:res.message,
                        type: 'success',
                        duration:1000,                       
                    });  
                    this.searchToolList()
                }else{
                    this.$message({
                        message:res.message,
                        type: 'error',
                        duration:1000,
                    });
                } 
            })
        },
        isbianji(row){
            this.centerDialogVisible1=true;
            this.toolForm=row
            this.imageUrl=this.files.myblogImageUrl+row.toolLogo
        },
        successBianji(){
            updateTool(this.toolForm).then((res)=>{
                if(res.code==200){
                    this.$message({
                        message:res.message,
                        type: 'success',
                        duration:1000,                       
                    });  
                    this.centerDialogVisible1=false
                    this.searchToolList()
                }else{
                    this.$message({
                        message:res.message,
                        type: 'error',
                        duration:1000,
                    });
                } 
            })
        },

        handleSizeChange(val) {//改变当前页的个数
            this.pageSize=val
            // console.log(`每页 ${val} 条`);
            // this.getSh()
        },
        handleCurrentChange(val) {//改变当前页码
              this.pageNum=val
              // console.log(`当前页: ${val}`);
              // this.getSh()
        }
    },
    mounted(){},
    created(){
        this.list()
        this.getTypeList()
    }
}
</script>

<style>

</style>