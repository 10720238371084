import request from "@/utils/requests";

// 上传图片
// export const fuwenImage=(param) =>request(
//     {url:'/uploadImage',
//     method:'post',
//     data:param
// })

export const shangchuanImages=(param) =>request(
    {url:'/uploadImage',
    method:'post',
    data:param
})

export const shangchuanVideo=(param) =>request(
    {url:'/uploadVideo',
    method:'post',
    data:param
})

export const uploadImagesToQiNiuYun=(param) =>request(
    {url:'/uploadToQiNiu',
    method:'post',
    data:param
})