<template>
    <div>
        <div style="padding: 10px 10px 0 10px;">
            <el-button type="primary" size="mini" plain @click="xinzeng">新增</el-button>
        </div>

        <div class="articlelist-table">
            <template>
                <el-table
                    :data="tableData"
                    :border="true"
                    :default-sort = "{prop: 'date', order: 'descending'}"
                    >
                    <el-table-column
                    header-align="center"
                    prop="createTime"
                    label="创建时间"
                    sortable
                    width="150">
                    </el-table-column>

                

                    <el-table-column
                    header-align="center"
                    prop="roleName"
                    label="角色名称"
                    width="140">
                    </el-table-column>

                    <el-table-column
                    header-align="center"
                    prop="roleDesc"
                    label="角色描述"
                    >
                    </el-table-column>

                                 
                    <el-table-column
                        header-align="center"
                        prop="isDisable"
                        label="是否禁用"
                        width="100">
                          <template slot-scope="scope">
                            <el-switch v-model="scope.row.isDisable" active-color="#13ce66"  @change="isDisable(scope.row.id,scope.row.isDisable)"
                             :active-value="1" :inactive-value="0">
                            </el-switch>
                          </template>
                    </el-table-column>

                    <el-table-column                  
                    header-align="center"                 
                    label="管理"
                    width="100">
                    <template slot-scope="scope">
                  
                     
                        <el-popconfirm                             
                            title="您确定删除吗？"
                            @confirm="isDelete(scope.row.id)">
                            <el-button title="删除" slot="reference"  type="danger" size="mini" icon="el-icon-delete" >删除</el-button>
                        </el-popconfirm>
                        
                        
                    </template>
                    </el-table-column>
                </el-table>
            </template>
        </div>

        <el-dialog
            title="新增角色"
            :visible.sync="centerDialogVisible">
            <el-form :model="roleForm">
                <el-form-item label="角色名称" :label-width="'80px'">
                    <el-input v-model="roleForm.roleName" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="角色描述" :label-width="'80px'">
                    <el-input v-model="roleForm.roleDesc" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="centerDialogVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="successXinZeng">新 增</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { addRole, rList, updateDisable, deleteRole } from '@/api/userRoleP'
export default {
    data(){
        return{
            tableData:[],
            centerDialogVisible:false,
            roleForm:{
                roleName:'',
                roleDesc:''
            }
        }
    },

    methods:{
        getRoleList(){
            rList().then((res)=>{
                 this.tableData=res.data
            })
        },

        xinzeng(){
            this.centerDialogVisible=true
            this.roleForm
        },

        successXinZeng(){
            addRole(this.roleForm).then((res)=>{
                if(res.code==200){
                    this.$message({
                        message:res.message,
                        type: 'success',
                        duration:1000,                       
                    });  
                    this.centerDialogVisible = false
                    this.getRoleList()
                }else{
                    this.$message({
                        message:res.message,
                        type: 'error',
                        duration:1000,
                    });
                } 
            })
        },
        // 是否禁用
        isDisable(id,d_id){
            updateDisable({id:id,isDisable:d_id}).then((res)=>{
            if(res.code==200){
                    this.$message({
                        message:res.message,
                        type: 'success',
                        duration:1000,                       
                    });  
                    this.getRoleList()
                }else{
                    this.$message({
                        message:res.message,
                        type: 'error',
                        duration:1000,
                    });
                } 
            })
        },

        // 删除
        isDelete(id){
            deleteRole(id).then((res)=>{
                if(res.code==200){
                    this.$message({
                        message:res.message,
                        type: 'success',
                        duration:1000,                       
                    });  
                    this.getRoleList()
                }else{
                    this.$message({
                        message:res.message,
                        type: 'error',
                        duration:1000,
                    });
                } 
            })
        }

    },
    created(){
        this.getRoleList()
    }
}
</script>

<style>

</style>