<template>
    <div>
        <div class="ax-title">
            <span>随笔管理</span>
        </div>
        <div class="article-list-top">
            <div style="display: flex;position: relative;">
                <el-button 
                    plain
                    size="small" 
                    type="primary" 
                    @click="xinzengSuiBi"     
                    style="margin-right: 10px;">
                    新建
                </el-button>
                <el-input
                    placeholder="请输入搜索内容"
                    v-model="search"
                    size="small"
                    prefix-icon="el-icon-search"
                    @keyup.enter.native="getSuiBiList"
                    clearable>
                </el-input>
                <el-button 
                    style="margin-left:10px;position:absolute;margin-top:3.5px;border:none;right:3px"
                    size="mini" 
                    type="primary">
                        搜索
                    </el-button>
            </div>
        </div>
        <div class="article-list-body">
            <div class="articlelist-table">
                <template>
                    <el-table
                        :data="tableData"
                        :border="true"
                        :default-sort = "{prop: 'date', order: 'descending'}"
                        >
                        <el-table-column
                        header-align="center"
                        prop="suibiCtime"
                        label="创建时间"
                        sortable
                        width="110">
                        </el-table-column>

                        <el-table-column
                        header-align="center"
                        prop="suibiText"
                        label="随笔文本">
                            <template slot-scope="scope">
                                <div v-html="scope.row.suibiText">
                                
                                </div>                               
                            </template>
                        </el-table-column>

                        <el-table-column
                        header-align="center"
                        prop="suibiImg"
                        label="图片"
                        width="150px">
                            <template slot-scope="scope">
                                <div style="display: flex;flex-wrap: wrap;align-items: center;justify-content: center;">
                                    <img style="height: 100px;" :src="files.myblogImageUrl+scope.row.suibiImg"/>
                                </div>                               
                            </template>
                        </el-table-column>
                    
                        <el-table-column
                        fixed="right"
                        header-align="center"                 
                        label="管理"
                        width="120">
                        <template slot-scope="scope">
                            <div style="display:grid;width:60px"> 
                                <el-button style="margin-left: 10px;margin-bottom:5px" type="primary" size="mini" icon="el-icon-edit"  @click="suibiBianji(scope.row)">编辑</el-button>
                                <el-popconfirm                             
                                    title="您确定删除这该标签吗？"
                                    @confirm="isDelete(scope.row.id)">
                                    <el-button title="删除" type="danger" size="mini" slot="reference"  icon="el-icon-delete" style="margin-left: 10px;" >删除</el-button>
                                    <!-- <el-button title="删除分类" slot="reference"  type="danger" size="mini" icon="el-icon-delete" circle style="margin-left: 20px;"></el-button> -->
                                </el-popconfirm>
                                <!-- <el-button type="info" size="mini" icon="el-icon-refresh" circle @click="huishou(scope.row.id)"></el-button> -->
                            </div>
                            
                        </template>
                        </el-table-column>
                    </el-table>
                </template>
            </div>
        </div>

        <el-dialog
            :title="suibiForm.id?'编辑随笔':'新增随笔'"
            :visible.sync="centerDialogVisible"
            top="5vh">
            <span style="padding:10px">
                <!-- <div style="display:flex;align-items: center;">
                    <span style="width:100px">随笔文案：</span>         
                        <el-input                      
                        placeholder="请输入相册文案"
                        type="textarea"
                        v-model="suibiForm.suibiText"
                        size="small"
                        clearable>
                    </el-input>          
                </div> -->
                <div>
                    <span style="font-size: 13px;">随笔文案：</span> 
                    <div class="a-notice" style="border: 1px solid #ccc;margin-top: 8px;">
                        
                            <!-- 工具栏 -->
                        <Toolbar style="border-bottom: 1px solid #ccc" :editor="editor" :defaultConfig="toolbarConfig" />
                        <div class="notice-leftandright">
                            <!-- 编辑器 -->
                            <div class="a-notice-left a-notice-zi">                 
                                <Editor style="height: 100%; overflow-y: hidden" :defaultConfig="editorConfig" v-model="suibiForm.suibiText"
                                @onChange="onChange" @onCreated="onCreated" />
                            </div>
                            <div class="a-notice-right a-notice-zi">
                                <div v-html="suibiForm.suibiText">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               
                <div class="h-suibiupload" style="display:flex;margin-top:10px;align-items: center;">
                    <span style="width:83px;">图片：</span>
                    <el-upload
                       
                        class="avatar-uploader"
                        action="string"
                        :show-file-list="false"
                        :http-request="uploadImage"
                        :before-upload="beforeAvatarUpload">
                        <img v-if="imageUrl" :src="imageUrl" style="height: 110px;">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </div>            
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="centerDialogVisible = false">取 消</el-button>                
                <el-button v-if="suibiForm.id" size="small" type="primary" @click="bianjiSeccuss">完成编辑</el-button>
                <el-button v-else size="small" type="primary" @click="suibiSeccuss">添 加</el-button>
            
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import { DomEditor } from "@wangeditor/editor";
import { getAllSuiBi, addSuiBi, updateSuiBi, deleteSuiBiById } from '@/api/suibi'
import { shangchuanImages } from '../api/uplod'

export default {
    name:"AdminSuiBi",
    components: { Editor, Toolbar},
    data(){
        return{
            search:'',
            tableData:[],


            editor: null,
            placeholder: null, // 富文本占位内容
            // height: '300px', // 富文本最小高度
            toolbarConfig: {
                // toolbarKeys: [ /* 显示哪些菜单，如何排序、分组 */ ],
                // excludeKeys: [  /* 隐藏哪些菜单 */ ],
            },
            editorConfig: {
                placeholder: "暂无",
            },     

            // visiBlieTitle:'新增随笔',
            centerDialogVisible:false,

            suibiForm:{
                id:'',
                suibiText:'',
                suibiImg:''
            },

            imageUrl:''
        }
    },
    methods:{
        getSuiBiList(){
            getAllSuiBi(this.search).then((res)=>{
                 this.tableData=res.data
                //console.log(res.data)
            })
        },

        xinzengSuiBi(){
            this.centerDialogVisible=true
            this.suibiForm={
                id:'',
                suibiText:'',
                suibiImg:''
            }
            this.imageUrl=''
        },
        onCreated(editor) {
        this.editor = Object.seal(editor); // 【注意】一定要用 Object.seal() 否则会报错
        this.toolbarConfig = {
                excludeKeys: [
                    "insertTable", // 插入表格                           
                    "codeBlock",
                    "group-image",
                    'group-video',
                    "fullScreen",
                    "todo", // 待办   
                    "divider", // 分割线
                    "blockquote", // 引用
                ]
            }
        },
        
        onChange(editor) {
        // onChange 时获取编辑器最新内容
            const toolbar = DomEditor.getToolbar(editor)
            //   console.log("工具栏配置", toolbar.getConfig().toolbarKeys); // 工具栏配置
        
        },

        // handleChange(file, fileList) {
        //     var _this = this;
        //     if (file.raw) {                    
        //         let reader = new FileReader()  
        //         reader.onload = function (e) {
        //             _this.contentHtml = e.target.result;
        //         };
        //         reader.readAsText(file.raw,'utf-8');
            
        //     }
        //     console.log(file, fileList);
        // },
        
        

         // 图片上传
        uploadImage(param){
            const formData=new FormData()
            formData.append('file',param.file)
            // formData.append('id',2)
            shangchuanImages(formData)
            .then((res)=>{
            this.$message({
                message:res.message,
                type: 'success',
                duration:1000,
                });
            this.imageUrl=this.files.myblogImageUrl+res.data
            this.suibiForm.suibiImg=res.data
            // console.log(this.imageUrl)
            })

        },
        // 上传前判断
        beforeAvatarUpload(file) {
            const isjpg = file.type === 'image/jpg';
                const isjpeg = file.type === 'image/jpeg';
                const isgif = file.type === 'image/gif';
                const ispng = file.type === 'image/png';
                const isJPG = file.type === 'image/JPG';
                const isJPEG = file.type === 'image/JPEG';
                const isGIF = file.type === 'image/GIF';
                const isPNG = file.type === 'image/PNG';
                if (!(isJPG || isJPEG || isGIF || isPNG || isjpg || isjpeg || isgif || ispng)) {
                    this.$message.error('只能上传JPG 、JPEG 、GIF、 PNG格式的图片!');
                }

            const isLt2M = file.size / 1024 / 1024 < 10;
                if (!isLt2M) {
                    this.$message.error('上传单张图片大小不能超过10MB!');
                }
                return (isJPG || isJPEG || isGIF || isPNG || isjpg || isjpeg || isgif || ispng) && isLt2M;
        } ,

        suibiSeccuss(){
            addSuiBi(this.suibiForm).then((res)=>{
                if(res.code==200){
                    this.$message({
                    message:res.message,
                    type: 'success',
                    duration:1000,
                    });
                    this.centerDialogVisible=false
                    this.getSuiBiList()
                }else{
                    this.$message({
                    message:res.message,
                    type: 'error',
                    duration:1000,
                    });
                }
            })
        },

        // 编辑按钮
        suibiBianji(row){
            this.centerDialogVisible=true
            // this.visiBlieTitle='编辑随笔'
            this.suibiForm.id=row.id
            this.suibiForm.suibiText=row.suibiText
            this.imageUrl=this.files.myblogImageUrl+row.suibiImg
            // console.log(row)
        },

        // 完成编辑
        bianjiSeccuss(){
            // console.log("wcbj:",this.suibiForm)
            updateSuiBi(this.suibiForm).then((res)=>{
                if(res.code==200){
                    this.$message({
                    message:res.message,
                    type: 'success',
                    duration:1000,
                    });
                    this.centerDialogVisible=false
                    this.getSuiBiList()
                }else{
                    this.$message({
                    message:res.message,
                    type: 'error',
                    duration:1000,
                    });
                }
            })
        },

        isDelete(id){
            // console.log(id)
            deleteSuiBiById(id).then((res)=>{
                if(res.code==200){
                    this.$message({
                    message:res.message,
                    type: 'success',
                    duration:1000,
                    });
                    this.centerDialogVisible=false
                    this.getSuiBiList()
                }else{
                    this.$message({
                    message:res.message,
                    type: 'error',
                    duration:1000,
                    });
                }
            })
        }

    },
    created(){
        this.getSuiBiList()
    }
}
</script>

<style>
.h-suibiupload .avatar-uploader-icon {
    width: 110px;
    height: 110px;
    line-height: 110px
}
.h-suibiupload .el-upload {
    width: auto;
}
</style>