import request from "@/utils/requests";

export const getWeb=() =>request(
    {url:`/admin/setting`,
    method:'get',
    // data:param
})
export const updateHomebgi=(webHomeImage) =>request( 
    { url: `/admin/setting/wh/${webHomeImage}`,
    method:'get',
    // data:param
})
export const updateWebXcbgi=(webXcbgi) =>request( 
    { url: `/admin/setting/wxc/${webXcbgi}`,
    method:'get',
    // data:param
})
export const updateSbbgi=(webSbbgi) =>request( 
    { url: `/admin/setting/wsb/${webSbbgi}`,
    method:'get',
    // data:param
})

export const updateFriendbgi=(webFriendbgi) =>request( 
    { url: `/admin/setting/wf/${webFriendbgi}`,
    method:'get',
    // data:param
})

export const updateLybgi=(webLybgi) =>request( 
    { url: `/admin/setting/wly/${webLybgi}`,
    method:'get',
    // data:param
})

export const updateToolbgi=(webToolbgi) =>request( 
    { url: `/admin/setting/tool/${webToolbgi}`,
    method:'get',
    // data:param
})

export const updateWebNotice=(param) =>request( 
    { url: `/admin/setting/webNotice`,
    method:'post',
    data:param
})


export const updateOther=(param) =>request(
    {url:'/admin/setting/updateOther',
    method:'post',
    data:param
})

export const admingetTagsAndCs=() =>request(
    {url:`/index`,
    method:'get',
    // data:param
})
