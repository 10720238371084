<template>
  <!-- 导航 -->
  <div class="a-aside">
    <el-radio-group v-model="isCollapse" style="margin-bottom: 0px;">
      <el-radio-button :label="true"  title="收起"><i class="el-icon-caret-left"></i></el-radio-button>
      <el-radio-button :label="false" title="展开"><i class="el-icon-caret-right"></i></el-radio-button>
    
    </el-radio-group>
      <el-menu default-active="1-4-1" 
      class="el-menu-vertical-demo" 
      @open="handleOpen" 
      @close="handleClose" 
      @select="handleSelect"
      :collapse="isCollapse">

      <el-menu-item index="/index" >
        <i class="el-icon-s-home"></i>
        <span slot="title">首页</span>
      </el-menu-item>

      <el-submenu index="/article">
        <template slot="title">
          <i class="el-icon-s-management"></i>
          <span slot="title">文章管理</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/article/write">
            <i class="el-icon-document-add"></i> 发布文章
          </el-menu-item>
          <!-- <el-menu-item index="/article/bjwz">编辑文章</el-menu-item> -->
          <el-menu-item index="/article/articleList">
            <i class="el-icon-document-copy"></i> 文章列表
          </el-menu-item>
          <el-menu-item index="/article/category">
            <i class="el-icon-pie-chart"></i> 文章分类
          </el-menu-item>
          <el-menu-item index="/article/tag">
            <i class="el-icon-price-tag"></i> 文章标签
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>

      <el-submenu index="/xcsb" >
        <template slot="title">
          <i class="el-icon-star-on"></i>
          <span slot="title">相册随笔</span>         
        </template>
        <el-menu-item-group>
          <el-menu-item index="/xcsb/xiangce">
            <i class="el-icon-picture-outline"></i>相册管理
          </el-menu-item>
          <el-menu-item index="/xcsb/suibi">
            <i class="el-icon-edit"></i>随笔管理
          </el-menu-item>       
          <el-menu-item index="/fl/friendLink">
            <i class="el-icon-link"></i>友链管理
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>

      
      <el-submenu index="/tl" >
        <template slot="title">
          <i class="el-icon-s-cooperation"></i>
          <span slot="title">工具管理</span>         
        </template>
        <el-menu-item-group>
          <el-menu-item index="/tl/type">
            <i class="el-icon-receiving"></i>工具分类
          </el-menu-item>
          <el-menu-item index="/tl/utool">
            <i class="el-icon-suitcase"></i>工具
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>

      <el-submenu index="/urp" >
        <template slot="title">
          <i class="el-icon-s-custom"></i>
          <span slot="title">用户角色权限</span>         
        </template>
        <el-menu-item-group>
          <el-menu-item index="/urp/user">
            <i class="el-icon-user"></i>用户
          </el-menu-item>
          <el-menu-item index="/urp/role">
            <i class="el-icon-mobile"></i>角色
          </el-menu-item>
          <el-menu-item index="/urp/permissions">
            <i class="el-icon-key"></i>权限
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>

    

      <el-submenu index="/web" >
        <template slot="title">
          <i class="el-icon-s-tools"></i>
          <span slot="title">网站设置</span>         
        </template>
        <el-menu-item-group>
          <el-menu-item index="/web/userSetting">
            <i class="el-icon-paperclip"></i>信息设置
          </el-menu-item>
          <el-menu-item index="/web/jbSetting">
            <i class="el-icon-set-up"></i>基本设置
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>

     
      <!-- <el-menu-item index="/web/setting">
        <i class="el-icon-setting"></i>
        <span slot="title">网站配置</span>
      </el-menu-item> -->
    </el-menu>
  </div>
</template>

<script>
export default {
    name:'AdminAside',
    data() {
      return {
        isCollapse: true,
      };
    },
    methods: {
      handleOpen(key, keyPath) {
        // console.log(key, keyPath);
      },
      handleClose(key, keyPath) {
        // console.log(key, keyPath);
      },
      handleSelect(key, keyPath) {
        // console.log(key, keyPath);
        this.$router.push(key)       
      }

    }
}
</script>

<style>
.a-aside{
  height: calc(100vh - 60px);
  overflow: auto;
  /* background-color: black; */
  /* border-right: 1px solid rgba(85, 85, 85, 0.445); */
  background-color: rgb(49, 49, 49);
  /* background: orange; */
  /* box-sizing: border-box; */
  z-index: 5;
  box-shadow: 0px 8px  5px 3px rgba(0, 0, 0, 0.3);
}
.el-radio-button__inner {
  border: none;
  padding: 5px 9px;
  /* background-color: rgb(245, 245, 245); */
}
.el-radio-button__inner, .el-radio-group{
  vertical-align:0;
}
.el-radio-button__inner:hover {
  background-color: aliceblue;
}
.el-radio-button:first-child .el-radio-button__inner,
.el-radio-button:last-child .el-radio-button__inner{
  border-radius: 0 0 0 0;
}

.el-menu{
  border-right: none;
  background-color: rgba(0, 0, 0, 0);
}
.el-menu-item-group{
  background-color: rgb(49, 49, 49);
}
.el-menu-item-group .el-menu-item{
  text-align: center;
}
.el-menu-item,.el-submenu__title{
  color: #fff;
  font-weight: 600;
}
.el-menu-item-group__title {
  padding: 0;
}
.el-submenu__title:hover {
  color: #0684fa;
  background-color: rgba(0, 0, 0, 0);
}
.el-menu-item:focus, .el-menu-item:hover {
  color: #0684fa;
  background-color: rgba(0, 0, 0, 0);
}
.el-menu-item.is-active {
  color: #fff;
  /* background-color: #0684fa; */
  background-color: #7272722e;
  border-left: 5px solid  #0684fa;
  /* border-radius: 5px; */
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
.el-tooltip__popper.is-dark {
  /* margin-top: 45px; */
  background-color: rgb(49, 49, 49);
  color: #fff;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  margin-left: 5px;
  border-radius: 0;
  font-size: 14px;
}
.el-tooltip__popper .popper__arrow, .el-tooltip__popper .popper__arrow::after {
  display: none;
}
</style>