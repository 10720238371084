import axios from "axios";
import { serverUrl } from '@/utils/config.js';
import router from "@/router";
import { Message} from 'element-ui';

// import store from '../store'

 // 注意！！ 这里是全局统一加上了 后端接口前缀 前缀，后端必须进行跨域配置！
const request=axios.create({
    baseURL:serverUrl,
    // baseURL:'http://localhost:8090/',
    // baseURL:'http://192.168.43.152:8090/',
    timeout:50000,
})

// request 拦截器
// 可以自请求发送前对请求做一些处理
// 比如统一加token，对请求参数统一加密
request.interceptors.request.use((config)=>{
    // console.log("是我config",config)
     config.headers['Authorization'] = sessionStorage.getItem("token")  // 设置请求头
    //  config.headers.Authorization=store.state.token 
     return config
}, error => {
    return Promise.reject(error)
})

// response 拦截器
// 可以在接口响应后统一处理结果
request.interceptors.response.use( 
    response => {   
    // console.log('拦截器里面拿到的初始数据', response)
        let res = response.data;
        if(res.code===401){
            router.replace({    
                path: '/login' // 到登录页重新获取token    
            }).catch(err => err)  
            Message.error(res.message) 
            sessionStorage.removeItem('nAndImage')
        }
        // if(res.code===403){
        //     Message.warning(res.message) 
        // }
       
            // 如果是返回的文件
            if (response.config.responseType === 'blob') {
                return res
            }
            // 兼容服务端返回的字符串数据
            if (typeof res === 'string') {
                res = res ? JSON.parse(res) : res
            }
            return res;
        
    },
    error => {
        // console.log('err' + error) // for debug
        return Promise.reject(error)
})


  

export default request