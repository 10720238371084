<template>
    <div class="article-list">
        <div class="article-list-top">
            <div style="display: flex;">
              <el-input
                placeholder="请输入搜索内容"
                v-model="search"
                size="small"
                prefix-icon="el-icon-search"
                @keyup.enter.native="searchArticleList()"
                clearable>
              </el-input>
              <el-button 
              style="margin-left:10px" 
              size="small" 
              @click="searchArticleList()"
              type="primary">
                搜索
              </el-button>
            </div>
        </div>
        <div class="article-list-body">
            <div class="articlelist-table">
                <template>
                    <el-table
                        :data="tableData.slice((pageNum - 1) * pageSize, pageNum * pageSize)"
                        :border="true"
                        :default-sort = "{prop: 'date', order: 'descending'}"
                        >
                        <el-table-column
                        header-align="center"
                        prop="createTime"
                        label="创建时间"
                        sortable
                        width="110">
                        </el-table-column>
                        <el-table-column
                        header-align="center"
                        prop="articleTitle"
                        width="200px"
                        label="标题">
                        </el-table-column>
                        <el-table-column
                        header-align="center"
                        prop="articleCover"
                        label="缩略图"
                        min-width="200px">
                          <template slot-scope="scope">      
                            <img style="height: 100px;" :src="files.myblogImageUrl+scope.row.articleCover" alt="">
                          </template>
                        </el-table-column>
                        <el-table-column
                        header-align="center"
                        prop="lCategory"
                        label="分类"
                        width="80">
                          <template slot-scope="scope">      
                            <el-tag v-if="scope.row.lCategory" size="small">{{scope.row.lCategory.categoryName}}</el-tag>
                          </template>
                        </el-table-column>
                        <el-table-column
                        header-align="center"
                        prop="tags"
                        label="标签"
                        min-width="150px">
                          <template slot-scope="scope">  
                            <div v-if="scope.row.lTags">
                              <el-tag v-for="(tag,idx) in scope.row.lTags" :key="idx" size="small"  style="margin:2px">
                                #{{tag.tagName}}
                              </el-tag>
                            </div>    
                          </template>
                        </el-table-column>
                        <el-table-column
                        header-align="center"
                        prop="articleType"
                        label="类型"
                        width="80">
                          <template slot-scope="scope">
                            <el-tag v-if="scope.row.articleType == 1" size="small" type="success">原创</el-tag>
                            <el-tag v-if="scope.row.articleType == 2" size="small" type="warning">转载</el-tag>
                            <el-tag v-if="scope.row.articleType == 3" size="small">翻译</el-tag>
                          </template>
                        </el-table-column>

                        <el-table-column
                        header-align="center"
                        prop="reading"
                        label="浏览量"
                        sortable
                        width="100">
                        </el-table-column>
                        <el-table-column
                        header-align="center"
                        prop="zan"
                        label="点赞量"
                        sortable
                        width="100">
                        </el-table-column>

                        <el-table-column
                        header-align="center"
                        prop="isTop"
                        label="置顶"
                        width="100">
                          <template slot-scope="scope">
                            <el-switch v-model="scope.row.isTop" active-color="#13ce66"  @change="istoTop(scope.row.id,scope.row.isTop)"
                            :disabled="scope.row.isDelete == 1" :active-value="1" :inactive-value="0">
                            </el-switch>
                          </template>
                        </el-table-column>
                        <el-table-column
                        header-align="center"
                        prop="isRecommend"
                        label="推荐"
                        width="100">
                          <template slot-scope="scope">
                            <el-switch v-model="scope.row.isRecommend" active-color="#13ce66" @change="istoReCommend(scope.row.id,scope.row.isRecommend)"
                            :disabled="scope.row.isDelete == 1" :active-value="1" :inactive-value="0">
                            </el-switch>
                          </template>
                        </el-table-column>

                        <el-table-column
                        header-align="center"
                        prop="isDelete"
                        label="删除&回收"
                        width="100">
                          <template slot-scope="scope">
                            <el-popconfirm
                                v-if="scope.row.isDelete==0"                              
                                title="您确定删除这该文章吗？"
                                @confirm="isDelete(scope.row.id)">
                                <el-button title="删除文章" slot="reference"  type="danger" size="mini" icon="el-icon-delete" plain>删除</el-button>
                            </el-popconfirm>

                            <el-popconfirm
                                v-else
                                title="您确定恢复该文章吗？"
                                @confirm="isHuishou(scope.row.id)">
                                <el-button title="恢复文章" slot="reference" type="success" size="mini" icon="el-icon-refresh" plain>恢复</el-button>
                            </el-popconfirm>
                          </template>
                        </el-table-column>

                        <el-table-column              
                        header-align="center"   
                        fixed="right"              
                        prop="address"
                        label="管理"
                        width="90">
                          <template slot-scope="scope">
                            <div style="display:flex">
                              <el-button title="编辑文章" type="primary" size="mini" icon="el-icon-edit" circle @click="bianji(scope.row.id)"></el-button>
                              
                              <el-popconfirm
                                style="margin-left: 10px;"
                                title="您确定y永久删除这一文章吗？"
                                @confirm="isforeverDelete(scope.row.id)">
                                <el-button title="删除文章"  slot="reference"  type="danger" size="mini" icon="el-icon-delete" circle >
                                </el-button>
                              </el-popconfirm>
                              <!-- <el-popconfirm
                                style="margin-left: 10px;"
                                title="您确定恢复该文章吗？"
                                @confirm="huishou(scope.row.id)">
                                <el-button title="恢复文章" :disabled="scope.row.isDelete==0" slot="reference" type="success" size="mini" icon="el-icon-refresh" circle ></el-button>
                              </el-popconfirm> -->
                            </div>
                            
                          </template>
                        </el-table-column>
                    </el-table>
                </template>
            </div>
        </div>
        <div style="padding: 10px;">
          <el-pagination  
            :current-page="pageNum"
            :page-sizes="[5, 10, 20, 30]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="tableData.length"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange">
          </el-pagination>
        </div>
    </div>
</template>

<script>
import { articlehuishou, articleifRecommend, articleifTop, deleteArticle, foreverDeleteArticle, getArticleAll } from '../api/article';
export default {
    name:"ArticleList",
    data() {
      return {
        search:'',
        tableData: [],
        pageNum:1,
        pageSize:10,
      }
    },
    methods: {

        // 获取全部和查找文章
        searchArticleList(){
          getArticleAll(this.search).then((res)=>{
            if(res.data){
              this.tableData=res.data         
            }
            
          })
        },
        // 是否置顶
        istoTop(id,top){
          // console.log(id,top)
          articleifTop({"id":id,"isTop":top}).then(res=>{
            if(res.code==200){              
              this.$message({
                message:res.message,
                type: 'success',
                duration:1000,
                });
              this.searchArticleList()  
            }else{
               this.$message({
                message:res.message,
                type: 'error',
                duration:1000,
              });
            } 
          })
        },
        // 是否推荐
        istoReCommend(id,recommned){
          // console.log(id,recommned)
          articleifRecommend({"id":id,"isRecommend":recommned}).then(res=>{
            if(res.code==200){
              this.$message({
                message:res.message,
                type: 'success',
                duration:1000,
                });
                this.searchArticleList()    
            }else{
                this.$message({
                message:res.message,
                type: 'error',
                duration:1000,
              });
            } 
          })
        },
        // 编辑文章
        bianji(id){
          this.$router.push({
              path:'/article/write',
              query:{article_id:id},
          })
        },
        // 删除文章
        isDelete(id){
          // console.log(id)
          deleteArticle({"id":id}).then(res=>{
            if(res.code==200){
              this.$message({
                message:res.message,
                type: 'success',
                duration:1000,
                });
                this.searchArticleList()    
            }else{
                this.$message({
                message:res.message,
                type: 'error',
                duration:1000,
              });
            } 
          })

        },
        // 回收文章
        isHuishou(id){
          // console.log(id)

          articlehuishou({"id":id}).then(res=>{
            if(res.code==200){
              this.$message({
                message:res.message,
                type: 'success',
                duration:1000,
                });
                this.searchArticleList()    
            }else{
                this.$message({
                message:res.message,
                type: 'error',
                duration:1000,
              });
            }                
          })
        },
        // 永久删除
        isforeverDelete(id){
          foreverDeleteArticle(id).then(res=>{
            if(res.code==200){
              this.$message({
                message:res.message,
                type: 'success',
                duration:1000,
                });
                this.searchArticleList()    
            }else{
                this.$message({
                message:res.message,
                type: 'error',
                duration:1000,
              });
            }       
          })
        },
        handleSizeChange(val) {//改变当前页的个数
            this.pageSize=val
            // console.log(`每页 ${val} 条`);
            // this.getSh()
        },
        handleCurrentChange(val) {//改变当前页码
              this.pageNum=val
              // console.log(`当前页: ${val}`);
              // this.getSh()
        }
    },
    created(){
      // this.getArticleList()
      this.searchArticleList()
    }
    
}
</script>

<style>
.article-list{
   
}
.article-list-top{
    padding: 10px 10px 0 10px;
    /* width: 100%; */ 
    /* background-color: beige; */
}
.article-list-body{
    /* height: 100%; */
    width: 100%;
    /* background-color: rgb(243, 243, 101); */
}
.articlelist-table{
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 10px;
    /* max-width: 100%; */
    /* overflow-x: auto; */
}
.articlelist-table .el-table {
    font-size: 13px;
}
.articlelist-table .el-table .cell {
    text-align: center;
}
</style>