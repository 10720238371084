import request from "@/utils/requests";

export const toolList=(search) =>request(
    {url:`/tool/list/${search}`,
    method:'get',
    // data:param
})

// 添加
export const addTool=(param) =>request(
    {url:'/tool/add',
    method:'post',
    data:param
})

export const updateTool=(param) =>request(
    {url:'/tool/update',
    method:'post',
    data:param
})

export const deleteTool=(id) =>request(
    {url:`/tool/delete/${id}`,
    method:'get',
    // data:param
})


// ===================================================================


export const toolTypeList=() =>request(
    {url:`/tp/list`,
    method:'get',
    // data:param
})

// 添加
export const addToolType=(param) =>request(
    {url:'/tp/add',
    method:'post',
    data:param
})

export const updateToolType=(param) =>request(
    {url:'/tp/update',
    method:'post',
    data:param
})

export const deleteToolType=(id) =>request(
    {url:`/tp/delete/${id}`,
    method:'get',
    // data:param
})

export const toolTypeInTool=() =>request(
    {url:`/tp/toolTypelist`,
    method:'get',
    // data:param
})

