import { IButtonMenu, IDomEditor } from '@wangeditor/editor'
var t=true
class FullButtonMenu {                       // JS 语法

    constructor() {
        this.title = '全屏' // 自定义菜单标题
        this.iconSvg = '<svg t="1717048210543" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8669" width="64" height="64"><path d="M368.896 192H224a32 32 0 0 0-32 32v137.888a32 32 0 0 0 64 0V256h112.896a32 32 0 0 0 0-64zM784.864 192H640a32 32 0 1 0 0 64h112.864v105.888a32 32 0 1 0 64 0V224a32 32 0 0 0-32-32zM368.896 777.92H256V672a32 32 0 1 0-64 0v137.92a32 32 0 0 0 32 32h144.896a32 32 0 1 0 0-64zM784.864 640a32 32 0 0 0-32 32v105.92H640a32 32 0 1 0 0 64h144.864a32 32 0 0 0 32-32V672a32 32 0 0 0-32-32z" fill="#020202" p-id="8670"></path><path d="M912 48h-800c-35.296 0-64 28.704-64 64v800c0 35.296 28.704 64 64 64h800c35.296 0 64-28.704 64-64v-800c0-35.296-28.704-64-64-64z m-800 864v-800h800l0.064 800H112z" fill="#020202" p-id="8671"></path></svg>' // 可选
        this.tag = 'button'
    }
    // 获取菜单执行时的 value ，用不到则返回空 字符串或 false
    getValue(editor) {                              // JS 语法
        return ' 全屏 '
    }

    // 菜单是否需要激活（如选中加粗文本，“加粗”菜单会激活），用不到则返回 false
    isActive(editor) {                    // JS 语法
        return false
    }

    isDisabled(editor) {                     // JS 语法
        return false
    }

    // 点击菜单时触发的函数
    exec(editor, value) {         
        let element; // 声明变量    
        // 通过id获取对应元素     
        element = document.getElementById("fw-zs");
        if(t){       
            console.log(element,value) 
            // 给对应元素赋上class 名为 fullscreen-editor
            element.classList.toggle("fullscreen-editor",true);
            t=false
        }else{          
            console.log(element,value) 
            // 给对应元素移除class 名为 fullscreen-editor
            element.classList.toggle("fullscreen-editor",false);
            t=true
        }
        // element.toggleClass('fullscreen-editor')     
    }

}

// 创建一个对象并对外暴露
const myFullButton = {
    key: 'myFullButton', // 定义 menu key ：要保证唯一、不重复（重要）
    factory() {
        return new FullButtonMenu() // 把 `YourMenuClass` 替换为你菜单的 class
    },
}

export default myFullButton