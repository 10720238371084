import request from "@/utils/requests";
// 用户列表
export const uList=() =>request(
    {url:`/user/userList`,
    method:'get',
    // data:param
})
// 添加用户
export const addUser=(param) =>request(
    {url:'/user/add',
    method:'post',
    data:param
})
// 删除
export const deleteUser=(id) =>request( 
    { url: `/user/delete/${id}`,
    method:'get',
    // data:param
})
//================================================================
// 给用户授予角色
export const userAddRoles=(param) =>request(
    {url:'/userRole/add',
    method:'post',
    data:param
})
export const userDeleteRoles=(param) =>request(
    {url:'/userRole/delete',
    method:'post',
    data:param
})

// 获取头像昵称
export const getUserById=(id) =>request( 
    { url: `/user/${id}`,
    method:'get',
    // data:param
})

//================================================================
// 获取角色列表
export const rList=() =>request(
    {url:`/role/roleList`,
    method:'get',
    // data:param
})
// 添加角色
export const addRole=(param) =>request(
    {url:'/role/add',
    method:'post',
    data:param
})
// 是否禁用角色
export const updateDisable=(param) =>request(
    {url:'/role/updateIsDisable',
    method:'post',
    data:param
})
export const deleteRole=(id) =>request( 
    { url: `/role/delete/${id}`,
    method:'get',
    // data:param
})

//================================================================
// 获取权限列表
export const pList=() =>request(
    {url:`/admin/permissionList`,
    method:'get',
    // data:param
})

export const adminLogin=(param) =>request(
    {url:'/login',
    method:'post',
    data:param
})