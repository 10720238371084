<template>
    <div>
        <div class="ax-title">
            <span>相册管理</span>
        </div>
        <div class="article-list-top">
            <div style="display: flex;position: relative;">
                <el-button 
                    plain
                    size="small" 
                    type="primary" 
                    @click="xinzengXiangce"     
                    style="margin-right: 10px;">
                    新建
                </el-button>
                <el-input
                    placeholder="请输入搜索内容"
                    v-model="search"
                    size="small"
                    prefix-icon="el-icon-search"
                    @keyup.enter.native="getXiangCeList()"
                    clearable>
                </el-input>
                <el-button 
                    style="margin-left:10px;position:absolute;margin-top:3.5px;border:none;right:3px"
                    size="mini" 
                    type="primary">
                        搜索
                    </el-button>
            </div>
        </div>
        <div class="article-list-body">
            <div class="articlelist-table">
                <template>
                    <el-table
                        :data="tableData.slice((pageNum - 1) * pageSize, pageNum * pageSize)"
                        :border="true"
                        :default-sort = "{prop: 'date', order: 'descending'}"
                        >
                        <el-table-column
                        header-align="center"
                        prop="xcCtime"
                        label="创建时间"
                        sortable
                        width="110">
                        </el-table-column>

                        <el-table-column
                        header-align="center"
                        prop="xctype"
                        label="类型"
                        width="80">
                            <template slot-scope="scope">
                                <el-tag v-if="scope.row.xcType==1"  size="small">
                                    日常
                                </el-tag>

                                <el-tag v-else  size="small">
                                    壁纸
                                </el-tag>
                            </template>
                        </el-table-column>

                        <el-table-column
                        header-align="center"
                        prop="xcText"
                        label="相册文本"
                        width="200">
                        </el-table-column>

                       

                        <el-table-column
                        header-align="center"
                        prop="xcImages"
                        label="图片列"
                        minWidth="300px">
                            <template slot-scope="scope">
                                <div style="display: flex;align-items: center;justify-content: center;flex-wrap: wrap;">
                                    <el-image
                                        v-for="(image,index) in scope.row.xcImages.split(',')" 
                                        :key="index" 
                                        style=" height: 100px;margin-left:10px;"
                                        @click="toBig(qiNiuLink+image)"
                                        :src="qiNiuLink+image" 
                                        :preview-src-list="srcList">
                                    </el-image>
                                </div>
                                
                            </template>
                        </el-table-column>

                        <el-table-column
                        header-align="center"
                        prop="xcDelete"
                        label="状态"
                        width="100">
                            <template slot-scope="scope">
                                <el-tag v-if="scope.row.xcDelete==0"  size="small" type="success">
                                    正常
                                </el-tag>

                                <el-tag v-else  size="small" type="warning">
                                    已删除
                                </el-tag>
                            </template>
                        </el-table-column>

                    
                        <el-table-column
                        fixed="right"
                        header-align="center"                 
                        label="管理"
                        width="120">
                        <template slot-scope="scope">
                            <div style="display:grid;width:60px"> 
                                <el-button style="margin-left: 10px;margin-bottom:5px" type="primary" size="mini" icon="el-icon-edit"  @click="xcbianji(scope.row)">编辑</el-button>
                                <el-button v-if="scope.row.xcDelete==0" type="warning" size="mini" icon="el-icon-delete"  @click="isDelete(scope.row.id)">删除</el-button>
                                <el-button v-else type="success" size="mini" icon=""  @click="isHuishou(scope.row.id)">回收</el-button>

                                <!-- <el-button type="info" size="mini" icon="el-icon-refresh" circle @click="huishou(scope.row.id)"></el-button> -->
                            </div>
                            
                        </template>
                        </el-table-column>

                        <el-table-column
                        fixed="right"
                        header-align="center"                 
                        label="彻底删除"
                        width="120">
                        <template slot-scope="scope"> 
                            <el-popconfirm                             
                                title="删除后不能恢复，您确定删除这该文章吗？"
                                @confirm="foreverDelete(scope.row.id)">
                                <el-button title="删除文章" slot="reference"  type="danger" size="mini" icon="el-icon-delete" plain>彻底删除</el-button>
                            </el-popconfirm>
                        </template>
                        </el-table-column>
                    </el-table>
                </template>
            </div>
        </div>

        
        <div style="padding: 10px;">
          <el-pagination  
            :current-page="pageNum"
            :page-sizes="[5, 10, 20, 30]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="tableData.length"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange">
          </el-pagination>
        </div>

        <!-- 对话框 -->
        <el-dialog
            :title="imageForm.id ? '编辑相册':'新增相册'"
            :visible.sync="centerDialogVisible">
            <span style="padding:10px">
                <div style="display:flex;align-items: center;">
                    <span style="width:100px">相册文案：</span>         
                        <el-input
                        placeholder="请输入相册文案"
                        v-model="imageForm.xcText"
                        size="small"
                        clearable>
                    </el-input>          
                </div>
                <div style="display:flex;margin-top:10px;align-items: center;">
                    <span style="width:83px;">图片类型：</span>   
                    <template>
                        <el-radio v-model="imageForm.xcType" label='1'>日常</el-radio>
                        <el-radio v-model="imageForm.xcType" label='2'>壁纸</el-radio>                         
                    </template>    
                </div>
                <!-- 多张图片上传 -->
                <div class="h-upload" style="display:flex;margin-top:10px;align-items: center;">
                    <span style="width:83px;">图片：</span>
                    <el-upload
                        ref="avatar-uploader"  
                        :class="{hide:hideUploadAdd}"  
                        action=""
                        :http-request="uploadImage"
                        list-type="picture-card"
                        multiple
                        :file-list="fileListUrl"
                        :on-change="handleAddChange"                   
                        :before-upload="beforeAvatarUpload"
                        :on-preview="handlePictureCardPreview"
                        :limit="99"       
                        :on-remove="handleRemove">
                        <i class="el-icon-plus"></i>
                        <div class="el-upload__tip" slot="tip">（请上传JPG 、JPEG 、GIF、 PNG格式的图片，每张大小不超过3M）</div>
                    </el-upload>
                    <!-- <el-dialog 
                    :append-to-body="true"
                    :visible.sync="imageDialogVisible">
                        <img width="100%" :src="dialogImageUrl" alt="">
                    </el-dialog> -->
                    <!-- 预览图片 -->
                    <transition enter-active-class="animate__animated animate__fadeIn 0.5s">
                        <image-viewer
                            v-if="imageDialogVisible"
                            :url-list="dialogImageUrl"
                            :on-close="closeViewer"
                            :z-index="3000"
                        />
                    </transition>
                </div>            
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="centerDialogVisible = false">取 消</el-button>
                
                <el-button v-if="imageForm.id" size="small" type="primary" @click="bianjiSuccess">完成编辑</el-button>
                <el-button v-else size="small" type="primary" @click="tianjiaXiangce">添 加</el-button>
            
            </span>
        </el-dialog>

    </div>
</template>

<script>
import { addXiangCe, getAllXiangCe, huishouXiangce, isDeleteXiangce, updateXiangCe, foreverDeleteXiangce } from '@/api/xiangce'
import { uploadImagesToQiNiuYun } from '@/api/uplod'
import ImageViewer from 'element-ui/packages/image/src/image-viewer'

export default {
    name:'AdminXiangCe',
    components: {
        ImageViewer,
    },
    data(){
        return{
            search:'',
            centerDialogVisible:false,
                  

            imagFile: [],

            imageForm:{
                id:'',
                xcText:'',
                // 照片列
                xcImages:'',
                xcType:"1"
            },

            //预览图片
            dialogImageUrl: '',
            //是否隐藏+号
            hideUploadAdd:false,
            imageDialogVisible: false,

            // 对话框里面上传的照片列回显
            fileListUrl:[
            // {
            //   uid: 1690818239790,
            //   url: "http://192.168.0.108:8090/tupian/222.jpeg"
            // }
            ],

            tableData:[],
            // 大图浏览列
            srcList:[],

            pageNum:1,
            pageSize:10,
        }
    },
    methods:{
         // 获取图片列
         getXiangCeList(){
            getAllXiangCe(this.search).then((res)=>{
                 this.tableData=res.data
                //console.log(res.data)
            })
        },

        // 新建
        xinzengXiangce(){
            this.centerDialogVisible = true
            // 初始化
            this.imageForm={
                id:'',
                xcText:'',
                xcImages:'',
                xcType:"1"
            }
            this.imagFile=[]
            this.fileListUrl=[]         
        },

        // 添加按钮
        tianjiaXiangce(){
        this.imageForm.xcImages= this.imagFile.toString()
        // console.log("数据：",this.imageForm)
        if(this.imageForm.xcText){
            if(this.imageForm.xcImages){
                addXiangCe(this.imageForm).then((res)=>{
                    // console.log(res)
                    if(res.code==200){
                        this.$message({
                        message:res.message,
                        type: 'success',
                        duration:1000,
                        });
                        this.centerDialogVisible=false
                        this.getXiangCeList()
                    }else{
                        this.$message({
                        message:res.message,
                        type: 'error',
                        duration:1000,
                        });
                    }
                    
                })          
            }else{
                this.$message({
                    message:"至少上传一张图片",
                    type: 'error',
                    duration:1000,
                });
            }
        }else{
            this.$message({
                message:"相册文案不能为空",
                type: 'error',
                duration:1000,
            });
        }
       
        // var arr=str.split(",")
        // console.log("字符转数组：",arr)
        },

        // 删除列表里面图片
        handleRemove(file, fileList) {
            this.imagFile.splice(this.imagFile.indexOf(file.uid), 1);
            this.fileListUrl=fileList
            if (fileList.length <=99) {
                this.hideUploadAdd = false
            }
        },
    
        /** 图片上传 */
        // uploadSuccess(res,file,fileList){
        // // this.fileListUrl.push(res.data)  //这里直接push会报错，但是改的话就不能把图片存成数组形式了，我还没有找到好的办法，所以只能先这样把功能实现了
        // // this.form.otherImg = this.fileListUrl.toString()    //把上传的图片转换成数组形式存在数据库的other_img字段里面
        // fileList.forEach(item => {
        //     console.log(item.toString())
        // })
        // // this.imagFile.uid=file.uid
        // // this.imagFile.url=file.url
        // // this.imagFile.status=file.status
        // // this.fileListUrl.push(this.imagFile)
        // console.log("数据：",this.fileListUrl)
        // console.log("flist数据：",fileList)
        // },

        // handleRemove1(file, fileList) {
        //     //删除图片的时候也要判断一下
        //     if (fileList.length < 5) {
        //       this.hideUploadAdd = false;
        //     }
        // },

        // 预览图片
        
        // handlePictureCardPreview(file) {
        // this.dialogImageUrl = file.url;
        // console.log("file",file)
        // this.imageDialogVisible = true;
        // },


         //如果图片超过五张，则不显示+号
        handleAddChange(file, fileList) {
            // console.log("上传",file, fileList);           
            if (fileList.length > 99) {
                this.hideUploadAdd = true;
            }
        },

        // 上传前判断
        beforeAvatarUpload(file) {
        const isjpg = file.type === 'image/jpg';
                const isjpeg = file.type === 'image/jpeg';
                const isgif = file.type === 'image/gif';
                const ispng = file.type === 'image/png';
                const isJPG = file.type === 'image/JPG';
                const isJPEG = file.type === 'image/JPEG';
                const isGIF = file.type === 'image/GIF';
                const isPNG = file.type === 'image/PNG';
                if (!(isJPG || isJPEG || isGIF || isPNG || isjpg || isjpeg || isgif || ispng)) {
                    this.$message.error('只能上传JPG 、JPEG 、GIF、 PNG格式的图片!');
                }

        const isLt2M = file.size / 1024 / 1024 < 10;
                if (!isLt2M) {
                    this.$message.error('上传单张图片大小不能超过10MB!');
                }
                return (isJPG || isJPEG || isGIF || isPNG || isjpg || isjpeg || isgif || ispng) && isLt2M;
        } ,

        // 上传图片
        uploadImage(param){
            const formData=new FormData()
            formData.append('file',param.file)
            // formData.append('id',this.userId)  
            uploadImagesToQiNiuYun(formData)
            .then((res)=>{
            // //console.log(res.data)
            this.imagFile.push(res.data)
            // this.fileListUrl.push({"uid":res.data,"url": "http://192.168.0.108:8090/tupian/"+res.data})
            this.$message({
                message:res.message,
                type: 'success',
                duration:1000,
                });
            })
        },

        // 预览大图
        handlePictureCardPreview(img){  
            // console.log("我是img",img)    
            this.onPreview([img.url]) 
            // this.dialogImageUrl=idx
            // this.onPreview([idx]) 
            // this.imgList.push(idx)
        },

        //开启富文本图片预览
        onPreview(img) {
            this.imageDialogVisible = true;  
            this.dialogImageUrl = img
       
        },

        //关闭图片预览
        closeViewer() {
            this.imageDialogVisible = false;
            // console.log("我关掉了")    
            document.body.style.overflow = 'auto';
        },

        // 表格图片大图预览
        toBig(img){
            this.srcList=[],
            this.srcList.push(img)
        },
        

        // 编辑按钮
        xcbianji(row){
            this.centerDialogVisible=true;
            // console.log(row)
       
            this.imageForm.xcText=row.xcText,
            this.imageForm.xcType=row.xcType.toString(),
            this.imageForm.id=row.id

            this.imagFile=row.xcImages.split(',')

            this.fileListUrl=[]
            row.xcImages.split(',').forEach(element => {
                //  console.log(element)            
                 this.fileListUrl.push({uid:element,url:this.qiNiuLink+element})
            });  
            // console.log('from',this.imageForm)
        },

        bianjiSuccess(){           
            this.imageForm.xcImages=this.imagFile.toString()
            if(!this.imageForm.xcImages){
                return this.$message({
                    message: '至少上传一张图片',
                    type: 'warning'
                });
            }
            // console.log("编辑",this.imageForm,"图片列",this.imagFile)
            updateXiangCe(this.imageForm).then((res)=>{
                if(res.code==200){
                        this.$message({
                        message:res.message,
                        type: 'success',
                        duration:1000,
                        });
                        this.centerDialogVisible=false
                        this.getXiangCeList()
                    }else{
                        this.$message({
                        message:res.message,
                        type: 'error',
                        duration:1000,
                        });
                    }
            })
        },

        isDelete(id){
            isDeleteXiangce({id:id,xcDelete:1}).then((res)=>{
                if(res.code==200){
                    this.$message({
                        message:res.message,
                        type: 'success',
                        duration:1000,
                    });  
                    this.getXiangCeList()
                }else{
                        this.$message({
                        message:res.message,
                        type: 'error',
                        duration:1000,
                    });
                } 
            })
            // console.log(id)
        },

        isHuishou(id){
            huishouXiangce({id:id,xcDelete:0}).then((res)=>{
                if(res.code==200){
                    this.$message({
                        message:res.message,
                        type: 'success',
                        duration:1000,
                    });  
                    this.getXiangCeList()
                }else{
                        this.$message({
                        message:res.message,
                        type: 'error',
                        duration:1000,
                    });
                } 
            })
        },

        foreverDelete(id){
            foreverDeleteXiangce(id).then((res)=>{
                if(res.code==200){
                    this.$message({
                        message:res.message,
                        type: 'success',
                        duration:1000,
                    });  
                    this.getXiangCeList()
                }else{
                        this.$message({
                        message:res.message,
                        type: 'error',
                        duration:1000,
                    });
                } 
            })
        },


        handleSizeChange(val) {//改变当前页的个数
            this.pageSize=val
            // console.log(`每页 ${val} 条`);
            // this.getSh()
        },
        handleCurrentChange(val) {//改变当前页码
              this.pageNum=val
              // console.log(`当前页: ${val}`);
              // this.getSh()
        }

    },

    // mounted(){
    //     console.log("链接",this.qiNiuLink)
    // },
    created(){
        this.getXiangCeList()
    }
}
</script>

<style>
    .hide .el-upload--picture-card {
		display: none !important;
	}
   .el-upload{
        /* line-height: 110px; */
        height: 110px;
        width: 110px;
    }
    .h-upload .el-upload-list--picture-card .el-upload-list__item-thumbnail {
        width:auto
    }
    .h-upload .el-upload-list--picture-card .el-upload-list__item {
        text-align: center;
        /* width: 110px; */
        height: 110px;
    }
    .el-upload, .el-upload-list--picture-card i{
        line-height: 110px;
    }

</style>