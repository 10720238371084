import request from "@/utils/requests";

export const getTagAll=(search) =>request(
    {url:`/tag/getAll?search=${search}`,
    method:'get',
    // data:param
})
// 增加标签
export const addTag=(param) =>request(
    {url:'/tag/add',
    method:'post',
    data:param
})

// 永久删除
export const foreverDeleteTag=(id) =>request(
    {url:`/tag/delete?id=${id}`,
    method:'get',
    // data:param
})

// 编辑分类
export const updateTag=(param) =>request(
    {url:'/tag/update',
    method:'post',
    data:param
})

export const deleteAllTag=(param) =>request(
    {url:'/tag/allDelete',
    method:'post',
    data:param
})