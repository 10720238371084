//定义全局变量
const serverUrl="http://www.xiangmeng.xyz/api"
// const serverUrl="http://10.195.107.134:8090/"
const qiNiuLink="http://qny.xiangmeng.xyz/"
// const files.myblogImageUrl = 'http://localhost:8090'
const files={
    // myblogImageUrl :'http://localhost:8090/files/image/',
    // myblogVideoUrl :'http://localhost:8090/files/video/'
    myblogImageUrl :serverUrl+'/files/image/',
    myblogVideoUrl :serverUrl+'/files/video/'
}
export {
    // files.myblogImageUrl
    files,
    serverUrl,
    qiNiuLink
}
