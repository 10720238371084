const userData={
    state:{
       nAndImage:''
    },
    getters:{
        nAndImage:state=>{
            let nAndImage=state.nAndImage
            if(!nAndImage){
                nAndImage=JSON.parse(window.sessionStorage.getItem('nAndImage'))
            }
            return nAndImage
        },
    },
    mutations:{
        setNAndImage:(state,nAndImage)=>{
            state.nAndImage=nAndImage;
            window.sessionStorage.setItem('nAndImage',JSON.stringify(nAndImage))
        },
    },

}
export default userData