var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"loginBgi"},[_c('div',{staticClass:"loginForm"},[_vm._m(0),_c('fieldset',[_c('legend',[_c('i',{staticClass:"el-icon-user-solid",class:{ touxIcon:this.formLabelAlign.username!=''&&this.formLabelAlign.password!='' }})]),_c('el-form',{ref:"adminform",attrs:{"label-width":"40px","model":_vm.formLabelAlign}},[_c('el-form-item',{attrs:{"label":"用户","prop":"username","rules":[
                        { required: true, message: '用户名不能为空'},
                        // {type:'',message: '用户名必须为数字或者字母'},
                        {pattern: /^[A-Za-z0-9]+$/,message: '只能包含字母、数字',trigger: 'blur'}
                        // { type: 'number', message: '年龄必须为数字值'}
                        ]}},[_c('el-input',{attrs:{"size":"small"},model:{value:(_vm.formLabelAlign.username),callback:function ($$v) {_vm.$set(_vm.formLabelAlign, "username", $$v)},expression:"formLabelAlign.username"}})],1),_c('el-form-item',{attrs:{"label":"密码","prop":"password","rules":[
                        { required: true, message: '密码不能为空'},
                        // {type:'',message: '用户名必须为数字或者字母'},
                        {pattern: /^[A-Za-z0-9]+$/,message: '只能包含字母、数字',trigger: 'blur'}
                        // { type: 'number', message: '年龄必须为数字值'}
                        ]}},[_c('el-input',{attrs:{"type":"password","size":"small"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.isLogin.apply(null, arguments)}},model:{value:(_vm.formLabelAlign.password),callback:function ($$v) {_vm.$set(_vm.formLabelAlign, "password", $$v)},expression:"formLabelAlign.password"}})],1),_c('div',{staticStyle:{"margin-bottom":"20px","height":"30px"}},[_c('el-button',{staticStyle:{"float":"left"},attrs:{"size":"mini"},on:{"click":_vm.huoqu}},[_vm._v("获取")]),_c('el-button',{staticStyle:{"float":"right"},attrs:{"type":"primary","size":"mini"},on:{"click":_vm.isLogin}},[_vm._v("登录")])],1)],1)],1)]),_c('BeiJing')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('div'),_c('span',[_c('img',{attrs:{"src":require("../../assets/images/logoko.png")}}),_vm._v("博客后台管理系统")])])
}]

export { render, staticRenderFns }