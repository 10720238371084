<template>
    <div>
        <el-card class="box-card">
            <div style="font-weight: 700;font-size: 18px;margin-bottom: 10px;">近六个月文章更新频率</div>
            <!-- <div id="zhutongji" style="height: 400px;"></div> -->
            <my-e-charts
                v-if="zhuOption.xAxis.data.length!=0"
                :fwidth="'100%'"
                :fheight="'400px'"
                :foption="zhuOption">
            </my-e-charts>
            
        </el-card>
        
        <div class="tji">
            <el-card class="box-card"  style="width: calc(100%/2 - 5px);">
            <div style="font-weight: 700;font-size: 18px;margin-bottom: 10px;">文章标签</div>
                <my-e-charts
                    v-if="option.series[0].data!=0"
                    :fwidth="'100%'"
                    :fheight="'400px'"
                    :foption="option">
                </my-e-charts>
                <!-- <div id="tongji1"></div> -->
            </el-card>

            <el-card class="box-card"  style="width: calc(100%/2 - 5px);margin-left:10px">
            <div style="font-weight: 700;font-size: 18px;margin-bottom: 10px;">文章分类</div>
                <my-e-charts
                    v-if="option1.series[0].data!=0"
                    :fwidth="'100%'"
                    :fheight="'300px'"
                    :foption="option1">
                </my-e-charts>
                <!-- <div id="tongji2"></div> -->
            </el-card>
        </div>
     
        
    </div>
    
</template>

<script>
// import * as echarts from 'echarts';
import { admingetTagsAndCs } from '@/api/setting';
import MyECharts from './MyECharts.vue';
// const zhuOption = {
//     tooltip: {
//         trigger: 'axis'
//     },
//     legend: {
//         left: 'left'
//     },
//     xAxis: {
//         type: 'category',
//         data: []
//     },
//     yAxis: {
//         type: 'value'
//     },
//     series: [
//         {
//         name: '文章数量',
//         data: [],
//         type: 'line',
//         smooth: true
//         },
//     ]
// }

// const option = {
//     // title: {
//     //     text: '文章标签',
//     // },
//     tooltip: {
//         trigger: 'item'
//     },
//     legend: {
//         top: '5%',
//         left: 'center'
//     },
//     series: [
//         {
//         name: 'Access From',
//         type: 'pie',
//         radius: ['40%', '70%'],
//         avoidLabelOverlap: false,
//         itemStyle: {
//             borderRadius: 10,
//             borderColor: '#fff',
//             borderWidth: 2
//         },
//         label: {
//             show: false,
//             position: 'center'
//         },
//         emphasis: {
//             label: {
//             show: true,
//             fontSize: 40,
//             fontWeight: 'bold'
//             }
//         },
//         labelLine: {
//             show: false
//         },
//         data: [
//             // { value: 1048, name: 'Search Engine',itemStyle: {color:'#fc8251'}},
//             // { value: 735, name: 'Direct' },
//             // { value: 580, name: 'Email' },
//             // { value: 484, name: 'Union Ads' },
//             // { value: 300, name: 'Video Ads' }
//             ]
//         }
//     ]
// };
// const option1 = {
//     // title: {
//     //     text: '文章分类',
//     // },
//     tooltip: {
//         trigger: 'item'
//     },
//     legend: {
//         top: '5%',
//         left: 'center'
//     },
//     series: [
//         {
//         name: 'Access From',
//         type: 'pie',
//         radius: ['40%', '70%'],
//         avoidLabelOverlap: false,
//         itemStyle: {
//             borderRadius: 10,
//             borderColor: '#fff',
//             borderWidth: 2
//         },
//         label: {
//             show: false,
//             position: 'center'
//         },
//         emphasis: {
//             label: {
//             show: true,
//             fontSize: 40,
//             fontWeight: 'bold'
//             }
//         },
//         labelLine: {
//             show: false
//         },
//         data: [
//             // { value: 1048, name: 'Search Engine',itemStyle: {color:'#fc8251'}},
//             // { value: 735, name: 'Direct' },
//             // { value: 580, name: 'Email' },
//             // { value: 484, name: 'Union Ads' },
//             // { value: 300, name: 'Video Ads' }
//             ]
//         }
//     ]
// };
export default {
    components: { MyECharts },

    data() {
        return {
            zon: '',
            zhuOption:{
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    left: 'left'
                },
                xAxis: {
                    type: 'category',
                    data: []
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        name: '文章数量',
                        data: [],
                        type: 'line',
                        smooth: true
                    },
                ]
            },

            option:{
                tooltip: {
                    trigger: 'item',
                },
                legend: {
                    type: 'scroll',
                    orient: 'vertical',
                    right: 10,
                    top: 20,
                    bottom: 20,
                },
                series: [
                    {
                    name: '姓名',
                    type: 'pie',
                    // radius: '55%',
                    radius: '40%',
                    center: ['40%', '50%'],
                    itemStyle: {
                        borderRadius: 5,
                        borderColor: '#fff',
                        borderWidth: 1
                    },
                    data: [],
                    emphasis: {
                            itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
                
            },

            option1:{
                // title: {
                //     text: '文章分类',
                // },
                // tooltip: {
                //     trigger: 'item'
                // },
                // legend: {
                //     top: '5%',
                //     left: 'center'
                // },
                // series: [
                //     {
                //     name: 'Access From',
                //     type: 'pie',
                //     radius: ['40%', '70%'],
                //     avoidLabelOverlap: false,
                //     itemStyle: {
                //         borderRadius: 10,
                //         borderColor: '#fff',
                //         borderWidth: 2
                //     },
                //     label: {
                //         show: false,
                //         position: 'center'
                //     },
                //     emphasis: {
                //         label: {
                //         show: true,
                //         fontSize: 40,
                //         fontWeight: 'bold'
                //         }
                //     },
                //     labelLine: {
                //         show: false
                //     },
                //     data: []
                //     }
                // ]
                tooltip: {
                    trigger: 'item',
                },
                legend: {
                    type: 'scroll',
                    orient: 'vertical',
                    right: 10,
                    top: 20,
                    bottom: 20,
                },
                series: [
                    {
                    name: '姓名',
                    type: 'pie',
                    radius: '50%',
                    // radius: '40%',
                    center: ['40%', '50%'],
                    itemStyle: {
                        borderRadius: 5,
                        borderColor: '#fff',
                        borderWidth: 1
                    },
                    data: [],
                    emphasis: {
                        itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                    }
                ]
            }
        };
    },
    methods:{
        getIndex(){
         
        }
    },
    mounted(){
        // var chartDom = document.getElementById('zhutongji');
        // var zhuChart = echarts.init(chartDom);
        // var chartDom1 = document.getElementById('tongji1');
        // var myChart1 = echarts.init(chartDom1);
        // var chartDom2 = document.getElementById('tongji2');
        // var myChart2 = echarts.init(chartDom2);
        // myChart.setOption(option);
        
        admingetTagsAndCs('').then(res => {
            this.zon = res.data;
            // console.log(res.data)
            // 柱状
            this.zhuOption.xAxis.data = this.zon.a?.bar?.map(v => v.date) || [];
            this.zhuOption.series[0].data = this.zon.a?.bar?.map(v => v.value) || [];
            // zhuChart.setOption(this.zhuOption);
            // 饼图
            this.option.series[0].data = this.zon.t?.bar || [];
  
            // myChart1.setOption(option);
            this.option1.series[0].data = this.zon.c?.bar || [];
            // myChart2.setOption(option1);
        });



    },
    created() {
       
       
    },
}
</script>

<style>
.tji{
    margin-top: 10px;
    /* min-height: 400px; */
    display: flex;
}
#tongji1,
#tongji2{ 
    min-height: 500px;
    /* width: 100%; */
}
@media (max-width:800px) {
    .tji{
        margin-top: 10px;
        /* min-height: 400px; */
        display: grid;
    }
    .tji .box-card{
        width: 100% !important;
    }
    .tji .box-card:nth-child(2){
        margin-left: 0 !important; 
        margin-top: 10px;
    }

}
</style>