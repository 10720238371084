<template>
    <div>
        <div class="ax-title">
            <span>文章标签</span>
        </div>
        <div class="article-list-top">
            <div style="display: flex;position: relative;">
                <el-button
                size="small"      
                @click="xinzengTagBuotton"      
                type="primary" plain>
                <i class="el-icon-plus"></i>
                    新增
                </el-button>
                <el-popconfirm                             
                    title="您确定删除这该标签吗？"
                    @confirm="allDelete">
            
                    <el-button 
                        slot="reference"
                        size="small" 
                        style="margin-right:10px;margin-left:10px"
                        type="danger" 
                        plain>
                        批量删除
                    </el-button>
                </el-popconfirm>
                

                <el-input
                    placeholder="请输入搜索内容回车即可"
                    v-model="search"
                    size="small"
                    prefix-icon="el-icon-search"
                    @keyup.enter.native="searchTagList()"
                    style=""
                    clearable>
                </el-input>
               
                    <el-button 
                    style="margin-left:10px;position:absolute;margin-top:3.5px;border:none;right:3px"
                    size="mini" 
                    type="primary">
                        搜索
                    </el-button>
                <!-- </div> -->               
            </div>
        </div>

        <div class="article-list-body">
            <div class="articlelist-table">
                <template>
                    <el-table
                        :data="tableData.slice((pageNum - 1) * pageSize, pageNum * pageSize)"
                        :border="true"
                        show-header
                        @selection-change="handleSelectionChange"
                        :default-sort = "{prop: 'date', order: 'descending'}"
                        >
                        <el-table-column
                        type="selection"
                        align="center"
                        label="全选"
                        width="60">

                        </el-table-column>
                        
                        <el-table-column
                        header-align="center"
                        prop="createTime"
                        label="创建时间"
                        sortable
                        width="150">
                        </el-table-column>

                        <el-table-column
                        header-align="center"
                        prop="tagColor"
                        label="标签颜色"
                        width="140">
                            <template slot-scope="scope">
                                <el-tag style="height:25px;width:50px" :style="{backgroundColor :`${scope.row.tagColor}`}" ></el-tag>
                            </template>
                        </el-table-column>

                        <el-table-column
                        header-align="center"
                        prop="tagName"
                        label="标签名"
                        >
                        </el-table-column>

                    
                        <el-table-column
                        header-align="center"
                        prop="articleList"
                        label="文章数量"
                        >
                            <template slot-scope="scope">     
                                {{ scope.row.articleList.length }}                               
                            </template>
                        </el-table-column>
                    
                        <el-table-column
                     
                        header-align="center"                 
                        label="管理"
                        width="100">
                        <template slot-scope="scope">
                            <div style="display:flex">
                            <el-button title="编辑表签" type="primary" size="mini" icon="el-icon-edit" circle @click="bianji(scope.row)"></el-button>
                            <el-popconfirm                             
                                title="您确定删除这该标签吗？"
                                @confirm="isDelete(scope.row.id)">
                                <el-button title="删除分类" slot="reference"  type="danger" size="mini" icon="el-icon-delete" circle style="margin-left: 20px;"></el-button>
                            </el-popconfirm>
                            </div>
                            
                        </template>
                        </el-table-column>
                    </el-table>
                </template>
            </div>
        </div>
        
        <div style="padding: 10px;">
          <el-pagination  
            :current-page="pageNum"
            :page-sizes="[5, 10, 20, 30]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="tableData.length"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange">
          </el-pagination>
        </div>

        <el-dialog
            :title="tagForm.id?'编辑标签':'添加标签'"
            :visible.sync="centerDialogVisible">
            <span style="padding:10px">
                <div style="display:flex;justify-content: center;align-items: center;width: 80%;">
                    <span style="width:100px">标签名字：</span>                   
                    <el-input
                    placeholder="请输入内容"
                    v-model="tagForm.tagName"
                    size="small"
                    clearable>
                    </el-input>
                </div>
                
                <div style="display:flex;margin-top:30px;align-items: center;">
                    <span style="width:83px;">标签颜色：</span>
                    <el-color-picker size="small" v-model="tagForm.tagColor"></el-color-picker>
                </div>
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="centerDialogVisible = false">取 消</el-button>
                <el-button v-if="!tagForm.id" size="small" type="primary" @click="successXinzeng">添 加</el-button>
                <el-button v-else size="small" type="primary" @click="successBianji">完成修改</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import { addTag, foreverDeleteTag, getTagAll, updateTag, deleteAllTag } from '../api/tags'
export default {
    name:"ArticleTag",
    data() {
        return {
            search:'',
            tableData: [],
            centerDialogVisible: false,
            tagForm:{
                id:'',
                tagName:'',
                tagColor:''
            },

            // 多选框
            multipleSelection: [],
            pageNum:1,
            pageSize:20,
        }
    },

    methods:{
        searchTagList(){
            getTagAll(this.search).then((res)=>{
                 this.tableData=res.data
            })
        },

        xinzengTagBuotton(){
            this.centerDialogVisible = true
            this.tagForm={
                id:'',
                tagName:'',
                tagColor:''
            }
        },

        successXinzeng(){
            // console.log("tag",this.tagForm)
            addTag(this.tagForm).then(res=>{
                if(res.code==200){
                    this.$message({
                        message:res.message,
                        type: 'success',
                        duration:1000,
                    });  
                    this.searchTagList()
                    this.centerDialogVisible=false
                }else{
                        this.$message({
                        message:res.message,
                        type: 'error',
                        duration:1000,
                    });
                } 
            })
        },

        bianji(row){
            this.centerDialogVisible=true
            this.tagForm=row
        },

        successBianji(){
            updateTag(this.tagForm).then(res=>{
                if(res.code==200){
                    this.$message({
                        message:res.message,
                        type: 'success',
                        duration:1000,
                    });  
                    this.searchTagList()
                    this.centerDialogVisible=false
                }else{
                        this.$message({
                        message:res.message,
                        type: 'error',
                        duration:1000,
                    });
                } 
            })
        },

        isDelete(id){
            foreverDeleteTag(id).then(res=>{
                if(res.code==200){
                    this.$message({
                        message:res.message,
                        type: 'success',
                        duration:1000,
                    });  
                    this.searchTagList()
                }else{
                        this.$message({
                        message:res.message,
                        type: 'error',
                        duration:1000,
                    });
                } 
            })

        },

        handleSelectionChange(val) {
            // list为空，表示全不选
            this.multipleSelection=val
        },

        allDelete(){
            if(this.multipleSelection.length==0){
                return this.$message({
                    message: '你未选择至少一个',
                    type: 'warning'
                });
            }
            let ids=this.multipleSelection.map(value => value.id)
            deleteAllTag(ids).then((res)=>{
                if(res.code==200){
                    this.$message({
                        message:res.message,
                        type: 'success',
                        duration:1000,
                    });  
                    this.searchTagList()
                }else{
                        this.$message({
                        message:res.message,
                        type: 'error',
                        duration:1000,
                    });
                } 
            })
        },

        handleSizeChange(val) {//改变当前页的个数
            this.pageSize=val
            // console.log(`每页 ${val} 条`);
            // this.getSh()
        },
        handleCurrentChange(val) {//改变当前页码
              this.pageNum=val
              // console.log(`当前页: ${val}`);
              // this.getSh()
        }

    },
    

    created(){
        this.searchTagList()
    }
}
</script>

<style>
/* .article-list-body .articlelist-table .el-table__header-wrapper .el-checkbox {
    display: none;
  } */



.ax-title{
    padding-top: 8px;
    text-align: center;
    /* background-color: aqua; */
}
.ax-title span{
    /* margin-left: 15px; */
    font-size: 15px;
    font-weight: 600;
    color: #4b4b4b;
}
</style>