// 该文件专门用于创建整个应用的路由器
import VueRouter  from "vue-router"
// 配置路由
import AdminLayout from "@/layout/AdminLayout";
import FaBiaoAndBianji from '@/pages/FaBiaoAndBianji'
import AdminIndex from '@/pages/AdminIndex'
import ArticleList from '@/pages/ArticleList'
import ArticleCategory from '@/pages/ArticleCategory'
import ArticleTag from '@/pages/ArticleTag'
import AdminXiangCe from '@/pages/AdminXiangCe'
import AdminSuiBi from '@/pages/AdminSuiBi'
import WebSetting from '@/pages/WebSetting'
import UserSetting from '@/pages/UserSetting'
import AdminLogin from '@/pages/login/AdminLogin'
import AdminFriendLink from '@/pages/AdminFriendLink'
import AdminUser from '@/pages/AdminUser'
import AdminRole from '@/pages/AdminRole'
import AdminPermissions from '@/pages/AdminPermissions'
import AdminTool from '@/pages/AdminTool'
import AdminToolType from '@/pages/AdminToolType'
import { Message} from 'element-ui';


const router= new VueRouter({
  routes:[
    { // 配置默认路由
      path:"/", // 路由地址
      redirect:"/login" // 重定向
    },
    {
      path:'/login',
      name:'登录',
      component: AdminLogin,
    },
    {
    path: '/index',
    // name: 'index',
    component: AdminLayout,
    children:[
      {
        path:'/index',
        redirect:'/index/home'
      },
     
      {
        path:'/index/home',
        name:'首页',
        component: AdminIndex,
      },
      {
        path:'/article/write',
        name:'编辑&发布',
        component: FaBiaoAndBianji,
      },
      {
        path:'/article/articleList',
        name:'文章列表',
        component: ArticleList,
      },
      {
        path:'/article/category',
        name:'文章分类',
        component: ArticleCategory,
      },
      {
        path:'/article/tag',
        name:'文章标签',
        component: ArticleTag,
      },
      {
        path:'/xcsb/xiangce',
        name:'相册管理',
        component: AdminXiangCe,
      },
      {
        path:'/xcsb/suibi',
        name:'随笔管理',
        component: AdminSuiBi,
      },
      {
        path:'/tl/type',
        name:'工具分类',
        component:AdminToolType
      },
      {
        path:'/tl/utool',
        name:'工具管理',
        component:AdminTool,
      },
      {
        path:'/fl/friendLink',
        name:'友链管理',
        component: AdminFriendLink,
      },
      {
        path:'/urp/user',
        name:'用户管理',
        component: AdminUser,
      },
      {
        path:'/urp/role',
        name:'角色管理',
        component: AdminRole,
      },
      {
        path:'/urp/permissions',
        name:'权限管理',
        component: AdminPermissions,
      },
      {
        path:'/web/userSetting',
        name:'信息设置',
        component: UserSetting,
      },
      {
        path:'/web/jbSetting',
        name:'基本设置',
        component: WebSetting,
      },


    ],
    
  },
 
  // {
  //   path: '/login',
  //   name: 'Login',
  //   component: ()=>import("@/views/Login")
  // },
  ]
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
    return originalPush.call(this, location).catch(err => err)
}
// 挂在路由导航守卫
router.beforeEach((to, from, next) => {
  // to 将要访问的路径
  // from 代表从哪个路径跳转而来
  // 访问登录页面 直接放行
  if (to.path === '/login') return next()
  const tokenStr = window.sessionStorage.getItem('token')
  // 没有token， 强制转到login页面
  if (!tokenStr){ 
    Message.error("检查到token不存在，请重新登录！") 
    return next('/login')
  }
  next()
})
 

export default router
