<template>
    <div style="padding: 10px;">
        空空如也~~
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>