<template>
  <div class="admin-layout">
    <AdminHeader></AdminHeader>
    <div style="display: flex;">
      <AdminAside></AdminAside>
      <router-view class="router-v" id="router-v"/>
    </div>
  </div>
</template>

<script>

import AdminHeader from '@/components/AdminHeader.vue'
import AdminAside from '@/components/AdminAside.vue'


export default {
  components: { AdminHeader, AdminAside},
  name: 'AdminLayout',

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.admin-layout{
  /* background-image: url(../assets/images/03.jpg); */
  /* background: rgb(62, 148, 218); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.router-v{
  flex: 1;  
  height: calc(100vh - 80px);
  width: calc(100% - 150px);
  overflow-y: auto;
  /* overflow-y: auto; */
  /* background-color: rgba(58, 54, 54, 0.37); */
  /* border-left: 0.5px solid rgb(146, 146, 146); */
}

@media (max-width:800px) {
  .a-header .admin-leftlanmu{
    display: block;
  }
  .a-aside{
    display: none;
  }
}
</style>
