<template>
    <div style="border: 1px solid #ccc;position: relative;height:100%">
      <!-- 工具栏 -->
      <Toolbar style="border-bottom: 1px solid #ccc;" :editor="editor" :defaultConfig="toolbarConfig" />
      <!-- 编辑器 -->
      <div class="bjq">
        <div class="fuwenb" id="fuwenb">
          <Editor style="height: 100%; overflow-y: hidden" :defaultConfig="editorConfig" v-model="articleHtml2"
            @onChange="onChange" @onCreated="onCreated" />
        </div>

        <div class="zhanshi" id="zhanshi" v-show="isShow">
          <zhang-shi :htmldata2="articleHtml2"></zhang-shi>
        </div>
      </div>

      <button style="position: absolute;right: 10px;bottom: 50px;" @click="isZhanshiShow()">
        <i class="el-icon-close" v-if="isShow"></i>
        <i class="el-icon-view" v-else></i>
      </button>
    </div>
    
</template>

<script>
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import { DomEditor } from "@wangeditor/editor";
import { Boot } from '@wangeditor/editor'
import ZhangShi from './ZhangShi.vue';
import { shangchuanImages, shangchuanVideo } from '@/api/uplod';
import  {myMenuIndent,myMenuBlockquote} from "@/wangEditorExtend/openWE.js"
import myFullButton from "@/wangEditorExtend/fullScreen"
import conf from '@/wangEditorExtend/elem-to-html'

Boot.registerMenu(myMenuIndent)
Boot.registerMenu(myMenuBlockquote)
Boot.registerMenu(myFullButton)
Boot.registerElemToHtml(conf)

export default {
  name:'FuWenBen',
  components: { Editor, Toolbar,ZhangShi },
  props:{
      htmldata1:String,
      required: true
  },
  data() {  
    return {
      editor: null,
      articleHtml2: '',
      placeholder: null, // 富文本占位内容
      uploadImageUrl: null, // 富文本上传图片的地址
      height: '300px', // 富文本最小高度
      isDisabled: false, // 富文本是否禁用
      toolbarConfig: {
        // toolbarKeys: [ /* 显示哪些菜单，如何排序、分组 */ ],
        // excludeKeys: [  /* 隐藏哪些菜单 */ ],
        insertKeys: {
            // index: 19,
            // keys: [
            //     // 'insertFormula', // “插入公式”菜单
            //     'myMenuIndent',
            // ],
        },
      },
      editorConfig: {
        placeholder: "请输入内容...",
        // autoFocus: false,
        // 所有的菜单配置，都要在 MENU_CONF 属性下
        MENU_CONF: {
          // 所有的菜单配置，都要在 MENU_CONF 属性下
          insertField: {
            insertField:[myMenuIndent,myMenuBlockquote,myFullButton] //设置插入域内容
          },
			    // 配置上传图片
          // insertImage:{
          //   customInsertImage: (imageUrl,insertFn) =>{
          //     // 插入图片的操作
          //     insertFn(imageUrl,'', imageUrl)
          //       // insertFn.cmd.do('insertHTML', '<video src="' +imageUrl+ '" controls="controls" width="100%"></video>');
          //     }
          // },
          // insertVideo:{
          //   // customInsertVideo: (videoUrl,insertFn) =>{
          //   //   // 插入视频的操作
          //   //   insertFn('insertHTML', '<iframe src="' + videoUrl + '" controls="controls" width="100%" allowfullscreen></iframe>');
          //   // }
          // },
            // 上传图片
          uploadImage: {
            base64LimitSize: 10 * 1024, // 5kb
            maxFileSize: 1000 * 1024 * 1024, //1g //设置大点 不然图片过大会报错

            customUpload: (result, insertFn) => {
              const formData=new FormData()
              formData.append('file',result)
              shangchuanImages(formData).then(res => {      
                  // 最后插入图片
                  insertFn(this.files.myblogImageUrl + res.data, 
                  result.name, 
                  this.files.myblogImageUrl + res.data)
                  // console.log(insertFn);
              })
            }           
          },
          
          insertVideo:{
            onInsertedVideo(videoNode) {                    // JS 语法
                if (videoNode == null) return 
                const { src } = videoNode
                // console.log('inserted video111', videoNode)
                // console.log('inserted video',src)
            },
            // checkVideo: this.customCheckVideoFn, // 也支持 async 函数
            parseVideoSrc: this.customParseVideoSrc, // 也支持 async 函数
          },

          // 上传视频
          uploadVideo:{
            customUpload:(file, insertVideoFn)=>{
              const formData=new FormData()
              formData.append('file',file)
              shangchuanVideo(formData).then(res => {    
                //console.log(res.data);  
                  // 最后插入
                insertVideoFn(this.files.myblogVideoUrl + res.data, file.name, this.files.myblogVideoUrl + res.data)
              })
            }
          },
        },       
        // MENU_CONF:{
        //   codeSelectLang:{
        //     codeLangs: [
        //         { text: 'CSS', value: 'css' },
        //         { text: 'HTML', value: 'html' },
        //         { text: 'XML', value: 'xml' },
        //         // 其他
        //     ]
        //   }
        // }
      }, 
      
      isShow:true,
    };
  },


  created() {
    // this.myMenuIndent = {
    //   key: 'myMenuIndent', // 定义 menu key ：要保证唯一、不重复（重要）
    //   factory() {
    //     return new MyIndent(this) // 把 `YourMenuClass` 替换为你菜单的 class
    //   },
    // }
  },

  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor); // 【注意】一定要用 Object.seal() 否则会报错
      this.toolbarConfig = {
        excludeKeys: [
          "fullScreen",
          // "blockquote"
        ]
      }

      //注册后没有插入的话进行插入
      if (!this.toolbarConfig.insertKeys) {
        this.toolbarConfig.insertKeys = {
          index: 32, // 插入的位置，基于当前的 toolbarKeys
          keys: ['myMenuIndent','myMenuBlockquote','myFullButton']
        }
      }

      // if (this.editor.getAllMenuKeys().indexOf('myMenuBlockquote') == -1) {
      //   Boot.registerMenu(this.myMenuBlockquote)
      // }

      // 注册后没有插入的话进行插入
      // if (!this.toolbarConfig.insertKeys) {
      //   this.toolbarConfig.insertKeys = {
      //     index: 2, // 插入的位置，基于当前的 toolbarKeys
      //     keys: ['myMenuBlockquote']
      //   }
      // }

    },


    onChange(editor) {
      // console.log("onChange", editor.getHtml()); // onChange 时获取编辑器最新内容
      const toolbar = DomEditor.getToolbar(editor)
      console.log("工具栏配置", toolbar.getConfig().toolbarKeys); // 工具栏配置
      this.$bus.$emit("getHtmlData",this.articleHtml2)
    },
    // 自定义校验视频
    // customCheckVideoFn(src, poster) { 
    // // function customCheckVideoFn(src, poster) {                                             // JS 语法
    //     if (!src) {
    //         return
    //     }
    //     if (src.indexOf('http') !== 0) {
    //         return '视频地址必须以 http/https 开头'
    //     }
    //     return true

    //     // 返回值有三种选择：
    //     // 1. 返回 true ，说明检查通过，编辑器将正常插入视频
    //     // 2. 返回一个字符串，说明检查未通过，编辑器会阻止插入。会 alert 出错误信息（即返回的字符串）
    //     // 3. 返回 undefined（即没有任何返回），说明检查未通过，编辑器会阻止插入。但不会提示任何信息
    // },
    // 自定义转换视频、哔哩哔哩视频
    customParseVideoSrc(vid) {               // JS 语法
        // if (src.includes('.bilibili.com')) {
        //     // 转换 bilibili url 为 iframe （仅作为示例，不保证代码正确和完整）
        //     const arr = location.pathname.split('/')
        //     const vid = arr[arr.length - 1]
        //     return `<iframe src="//player.bilibili.com/player.html?bvid=${vid}" scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true"> </iframe>`
        // }
        //  return src
            // 转换 bilibili url 为 iframe （仅作为示例，不保证代码正确和完整）
        // return `<iframe src="//player.bilibili.com/player.html?bvid=${vid}" scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true"> </iframe>`
      return `<iframe 
              style="position: absolute; width: 100%; height: 100%; left: 0; top: 0;" 
              src="//player.bilibili.com/player.html?autoplay=0&amp;bvid=${vid}" 
              scrolling="no" 
              border="0" 
              frameborder="no" 
              framespacing="0" 
              allowfullscreen="true"><ifram>`
                // +"</div>"  '<div style="position: relative;padding: 30% 45%;">'+
    },


    // update(file, insertFn) {
    //     // file 即选中的文件
    //     // 自己实现上传，并得到图片 url alt href
    //     // let name = md5(file.name);
    //     // let suffix = file.type.split("/")[1];
    //     // name = name + '.' + suffix;
    //     const formData=new FormData()
    //     formData.append('file',file)
    //     shangchuanImages(formData).then(res => {      
    //         // 最后插入图片
    //         //console.log(res.data);
    //         insertFn(this.files.myblogImageUrl + res.data, 
    //         file.name, 
    //         this.files.myblogImageUrl + res.data)
    //     })
    //   }

    // getEditorText() {
    //   const editor = this.editor;
    //   if (editor == null) return;
    //   console.log(editor.getText()); // 执行 editor API
    // },
    // printEditorHtml() {
    //   const editor = this.editor;
    //   if (editor == null) return;
    //   console.log(editor.getHtml()); // 执行 editor API
    // },

    isZhanshiShow(){
      if(this.isShow){
        this.isShow=false;
        document.getElementById("fuwenb").style.width="100%"
      }else{
        this.isShow=true;
        document.getElementById("fuwenb").style.width="50%"
        document.getElementById("zhanshi").style.width="50%"       
      }
    },
    


  },


  mounted() {
    // 模拟 ajax 请求，异步渲染编辑器
    setTimeout(() => {
      this.articleHtml2 =this.htmldata1;
    }, 1500);

  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁 editor ，重要！！！
  },
}
</script>

<style scoped>
@import '@wangeditor/editor/dist/css/style.css';
/* >>>.w-e-bar-bottom .w-e-select-list {
  bottom: -400px;
} */
/* 暗色主题 */

.bjq{
  display: flex;
  width: 100%;
  /* height: calc(100vh - 150px) */
  /* min-height: calc(80vh - 40px); */
  height: 100%;
}
.fuwenb{
  width: 100%;
  width: 50%;
  overflow: auto;
}
.zhanshi{
	  background-color: #fcfcfc;	
    overflow: auto;
    border-left: 1px solid rgba(100, 100, 100, 0.692);
    /* height: 100%; */
    width: 50%;
}
@media (max-width:750px) {
  .bjq{
    display: grid;
    min-height: 0px;
  }
  .fuwenb{
    width: 100%;
    height: 400px;
  }
  .zhanshi{
      width: 100%;
      height: 400px;
      border-top: 1px solid rgb(100, 100, 100);
      border-left: none;
  }
}

</style>