import request from "@/utils/requests";

export const getAdminInfo=(id) =>request(
    {url:`/admin/adminInfo?id=${id}`,
    method:'get',
    // data:param
})

// 获取所有文章
export const getArticleAll=(search) =>request(
    {url:`/admin/getList?search=${search}`,
    method:'get',
    // data:param
})

// 发表文章
export const addArticle=(param) =>request(
    {url:'/admin/addArticle',
    method:'post',
    data:param
})

// 删除文章
export const deleteArticle=(param) =>request(
    {url:'/admin/delete',
    method:'post',
    data:param
})

// 回收文章
export const articlehuishou=(param) =>request(
    {url:'/admin/huishou',
    method:'post',
    data:param
})
// 永久删除
export const foreverDeleteArticle=(id) =>request(
    {url:`/admin/foreverDelete?id=${id}`,
    method:'get',
    // data:param
})
// 设置置顶
export const articleifTop=(param) =>request(
{
    url:'/admin/ifTop',
    method:'post',
    data:param
})
// 设置推荐
export const articleifRecommend=(param) =>request(
{url:'/admin/ifRecommend',
    method:'post',
    data:param
})
// 编辑页面按id获取文章
export const getArticleVoById=(id) =>request(
    {url:`/admin/bianJiarticle?id=${id}`,
    method:'get',
    // data:param
})

// 编辑发表文章
export const updateArticle=(param) =>request(
    {url:'/admin/updateArticle',
    method:'post',
    data:param
})