<template>
    <div>
        <div style="padding: 10px 10px 0 10px;display:flex">
            <el-button type="primary" size="mini" plain @click="add">新增</el-button>
        </div>

        <div class="articlelist-table">
            <template>
                <el-table
                    :data="tableData"
                    :border="true"
                    :default-sort = "{prop: 'date', order: 'descending'}"
                    >
                    <el-table-column
                    header-align="center"
                    prop="createTime"
                    label="创建时间"
                    sortable
                    width="150">
                    </el-table-column>

                    <el-table-column
                    header-align="center"
                    prop="typeName"
                    label="分类名称"
                    width="">
                    </el-table-column>

                    <el-table-column
                    header-align="center"
                    prop="typeName"
                    label="工具的数量"
                    width="250">
                        <template slot-scope="scope">
                            <span>{{ scope.row.tools.length }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column                  
                    header-align="center"                 
                    label="管理"
                    width="180">
                    <template slot-scope="scope">
                        <div style="display:flex;">
                            <el-button type="primary" size="mini" icon="el-icon-edit"  @click="isbianji(scope.row)">编辑</el-button>
                     
                            <el-popconfirm                             
                                title="删除该分类对应的该分类的工具也会被删除，你确定删除吗"
                                @confirm="isDelete(scope.row.id)">
                                <el-button title="删除" slot="reference"  type="danger" size="mini" icon="el-icon-delete" style="margin-left:10px">删除</el-button>
                            </el-popconfirm>
                        </div>
                                                                      
                    </template>
                    </el-table-column>
                </el-table>
            </template>
        </div>

        <el-dialog
            title="新增"
            :visible.sync="centerDialogVisible">
            <el-form :model="toolTypeForm">
                <el-form-item label="分类名" :label-width="'80px'">
                    <el-input v-model="toolTypeForm.typeName" autocomplete="off"></el-input>
                </el-form-item>
  
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="centerDialogVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="successAdd">新 增</el-button>
            </span>
        </el-dialog>

        <el-dialog
            title="编辑"
            :visible.sync="centerDialogVisible1">
            <el-form :model="toolTypeForm">
                <el-form-item label="分类名" :label-width="'80px'">
                    <el-input v-model="toolTypeForm.typeName" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="centerDialogVisible1 = false">取 消</el-button>
                <el-button size="small" type="primary" @click="successBianji">完成编辑</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { addToolType, toolTypeList, deleteToolType, updateToolType } from '../api/tool'
export default {
    name:'AdminToolType',
    data(){
        return{
            tableData:[],
            centerDialogVisible:false,
            centerDialogVisible1:false,
            toolTypeForm:{
                typeName:''
            }
        }
    },
    methods:{
        add(){
            this.centerDialogVisible=true
            this.toolTypeForm.typeName=''
        },
        successAdd(){
            if(!this.toolTypeForm.typeName){
                return this.$message({
                    message: '名称不能为空',
                    type: 'warning'
                });
            }
            addToolType(this.toolTypeForm).then((res)=>{
                if(res.code==200){
                    this.$message({
                        message:res.message,
                        type: 'success',
                        duration:1000,                       
                    });  
                    this.centerDialogVisible=false
                    this.list()
                }else{
                    this.$message({
                        message:res.message,
                        type: 'error',
                        duration:1000,
                    });
                } 
            })
        },
        list(){
            toolTypeList().then((res)=>{
                this.tableData=res.data
                // console.log(res.data)
            })
        },
        isDelete(id){
            deleteToolType(id).then((res)=>{
                // console.log(res.data)
                if(res.code==200){
                    this.$message({
                        message:res.message,
                        type: 'success',
                        duration:1000,                       
                    });  
                    this.list()
                }else{
                    this.$message({
                        message:res.message,
                        type: 'error',
                        duration:1000,
                    });
                } 
            })
        },
        isbianji(val){
            this.centerDialogVisible1=true
            this.toolTypeForm=val
        },
        successBianji(){

            updateToolType(this.toolTypeForm).then((res)=>{
                if(res.code==200){
                    this.$message({
                        message:res.message,
                        type: 'success',
                        duration:1000,                       
                    });  
                    this.centerDialogVisible1=false
                    this.list()
                }else{
                    this.$message({
                        message:res.message,
                        type: 'error',
                        duration:1000,
                    });
                } 
            })
        }
    },
    mounted(){},
    created(){
        this.list()
    }
}
</script>

<style>

</style>