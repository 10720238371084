<template>
    <div class="loginBgi">
        <div class="loginForm">
            <div style="">
                <div></div>
                <!-- <img src="../../assets/images/03.jpg"/> -->
                
                <span> <img src="../../assets/images/logoko.png" >博客后台管理系统</span>
            </div>

			<fieldset>
                <legend>
                    <i :class="{ touxIcon:this.formLabelAlign.username!=''&&this.formLabelAlign.password!='' }" class="el-icon-user-solid"></i>
                </legend>
			    
                <el-form ref="adminform"  label-width="40px" :model="formLabelAlign">
                    <el-form-item label="用户" prop="username" :rules="[
                        { required: true, message: '用户名不能为空'},
                        // {type:'',message: '用户名必须为数字或者字母'},
                        {pattern: /^[A-Za-z0-9]+$/,message: '只能包含字母、数字',trigger: 'blur'}
                        // { type: 'number', message: '年龄必须为数字值'}
                        ]">
                        <el-input v-model="formLabelAlign.username" size="small"></el-input>
                    </el-form-item>
                    <el-form-item label="密码" prop="password" :rules="[
                        { required: true, message: '密码不能为空'},
                        // {type:'',message: '用户名必须为数字或者字母'},
                        {pattern: /^[A-Za-z0-9]+$/,message: '只能包含字母、数字',trigger: 'blur'}
                        // { type: 'number', message: '年龄必须为数字值'}
                        ]">
                        <el-input v-model="formLabelAlign.password" type="password" size="small" @keyup.enter.native="isLogin" ></el-input>
                    </el-form-item>

                    <div style=";margin-bottom: 20px;height: 30px;">
                        <el-button style="float: left;"  size="mini" @click="huoqu">获取</el-button>
                        <el-button style="float: right;"  type="primary" @click="isLogin" size="mini">登录</el-button>
                    </div>
                                     
                </el-form>
			</fieldset>				
		</div>
        <BeiJing></BeiJing>
    </div> 

</template>

<script>
import { adminLogin } from '@/api/userRoleP'
import { getUserById } from '../../api/userRoleP'
import BeiJing from './BeiJing.vue'
export default {
    data() {
        return {
            formLabelAlign: {
                username: '',
                password: '',
            }
        };
    },
    methods: {
        // isLogin(){
        //     if(this.formLabelAlign.username==this.formLabelAlign.password){
        //         this.$router.push({
        //         path:'/',
        // 		query:{
        // 			t:Date.now()
        // 		}, 
        //     })
        //     }
        // },
        huoqu() {
        },
        isLogin() {
            adminLogin(this.formLabelAlign).then((res) => {
                // console.log(res)
                if (res.code == 200) {
                    this.$message({
                        message: res.message,
                        type: 'success',
                        duration: 1000,
                    });
                    sessionStorage.setItem("token", res.data.token);
                    this.$router.push({
                        path: '/index',
                        query: {
                            id: res.data.id,
                            t: Date.now()
                        },
                    });
                    getUserById(res.data.id).then((res) => {
                        // console.log("不kong",res)
                        this.$store.commit('setNAndImage', res.data);
                        // this.nAndImage=res.data
                    });
                }
                else {
                    this.$message({
                        message: res.message,
                        type: 'error',
                        duration: 1000,
                    });
                }
            });
        }
    },
    mounted() {
        this.$nextTick(() => {
            if (this.formLabelAlign.username != '' || this.formLabelAlign.password != '')
                this.$refs['adminform'].validate();
        });
    },
    components: { BeiJing }
}
</script>

<style>
.loginBgi .el-form-item label::before{
    display: none;
}
.loginBgi{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    object-fit: cover;
    background: rgba(0, 0, 0, 0.266);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.loginBgi::before{
	content: "";
	position: absolute;
	z-index: -1;  
	width:100%;
	height: 100%;
    background-image: url(../../assets/images/111.jpg);
	background-size: 100% 100%  ;         
	background-position: center;
	background-size: cover;
	opacity: 1; 
}
.loginForm{
    z-index: 2;
    top: -70px;
    position: relative;
	/* position: absolute; */
    width:480px;
    min-height: 100px;
    /* background: hsla(0, 0%, 4%, 0.226);	 */
    /* background: #ffffff6e;	 */
    background: #00000042;
    border: 1px solid #9694943d;
    border-radius: 10px;
    backdrop-filter: blur(5px);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.589)

}
.loginForm fieldset {	
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    /* position: absolute;	 */
    display: flex;
    border: none
}
    
.loginForm legend {
    background: hsl(0, 0%, 100%);		
    /* border: 1px solid #464444b7; */
    font-size:24px;
    text-align: center;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    position: absolute;
    transition: .5s;
    top: -25px;
    left: calc(100%/2 - 30px);
    /* width: 4em; */
    /* border: 2px solid #660033; */
    border-radius: 50%;
}
.loginForm legend .touxIcon{
    transition: .5s;
    font-size: 30px;
    color: #4082EF;
}
.loginForm>div{
    overflow: hidden;
    border-radius: 10px 10px 0 0;
    height: 120px;
    width: 100%;
    border-bottom: 2px solid #ffffff;
    position: relative;
}
.loginForm>div img{	
    position: absolute;
    object-fit: cover;
    height: 100%;
    width: 100%;
}
.loginForm>div span{
    width: 100%;
    height: 100%;
    position: absolute;
    font-weight: 600;
    font-size: 25px;
    letter-spacing: 5px;
    /* top: 50px; */
    display: flex;
    justify-content: center;
    align-items: center;
    color: transparent;
    text-shadow:
    0px 0px 1px rgba(255, 255, 255, 0.753),
    0px 4px 4px rgba(0, 0, 0, 0.411);
}
.loginForm>div span img{
    height: 30px;
    width:30px;
    padding: 2px;
    border-radius: 5px;
    margin-right: 10px;
    background: #cccccc;
    position: relative;
}
    
.loginForm form{
    margin-top: 30px;
    width: 70%;
    /* margin-left: 5%; */
}
.loginForm form .el-input__inner {
    font-weight: 700;
    letter-spacing: 1.5px;
}
@media (max-width:520px) {	
    .loginForm{
        width:90%;
    }
}
</style>